import _map from "lodash/map";
import _get from "lodash/get";

var _PropTypes$shape;

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FILE_TYPE_LABELS } from '@bundle-stats/utils';
import { FileName } from '../../ui/file-name';
import { Tooltip } from '../../ui/tooltip';
import { FiltersDropdown } from '../../ui/filters-dropdown';
import { SortDropdown } from '../../ui/sort-dropdown';
import { MetricsTable } from '../metrics-table';
import { JobName } from '../job-name';
import { FILTER_ASSET, FILTER_CHANGED, FILTER_ENTRY, FILTER_CHUNK, FILTER_INITIAL } from './bundle-assets.constants';
import css from './bundle-assets.module.css';
var RUN_TITLE_CURRENT = 'Current';
var RUN_TITLE_BASELINE = 'Baseline';
var RUNS_LABELS = [RUN_TITLE_CURRENT, RUN_TITLE_BASELINE];

var getFileTypeFilters = function getFileTypeFilters() {
  return Object.entries(FILE_TYPE_LABELS).map(function (_ref) {
    var _ref2;

    var id = _ref[0],
        label = _ref[1];
    return _ref2 = {}, _ref2[id] = {
      label: label,
      defaultValue: true
    }, _ref2;
  }).reduce(function (agg, current) {
    return _objectSpread({}, agg, {}, current);
  }, {});
};

var addRunLabel = function addRunLabel(run, index, runs) {
  var internalBuildNumber = _get(run, 'meta.internalBuildNumber');

  var name = "Job #" + (internalBuildNumber || runs.length - index);
  var label = internalBuildNumber ? React.createElement(JobName, {
    title: index === 0 ? RUN_TITLE_CURRENT : RUN_TITLE_BASELINE,
    internalBuildNumber: internalBuildNumber
  }) : name;
  return _objectSpread({}, run, {
    name: name,
    label: label
  });
};

var TooltipNotPredictive = function TooltipNotPredictive(_ref3) {
  var runs = _ref3.runs;
  return React.createElement("div", {
    className: css.tooltipNotPredictive
  }, React.createElement("p", {
    className: css.tooltipNotPredictiveText
  }, "File name is the same, but the size has changed:"), React.createElement("table", {
    className: css.tooltipTable
  }, React.createElement("tr", null, runs.map(function (_ref4, index) {
    var name = _ref4.name,
        value = _ref4.value;
    var key = index;
    return React.createElement("tr", {
      key: key
    }, React.createElement("th", null, RUNS_LABELS[index]), React.createElement("td", null, name), React.createElement("td", null, value));
  }))));
};

TooltipNotPredictive.defaultProps = {
  runs: []
};
TooltipNotPredictive.propTypes = {
  runs: PropTypes.array // eslint-disable-line react/forbid-prop-types

};

var TooltipFilename = function TooltipFilename(_ref5) {
  var runs = _ref5.runs,
      labels = _ref5.labels;
  return React.createElement("div", {
    className: css.tooltipFilename
  }, runs.map(function (run, index) {
    var key = index;
    return React.createElement("div", {
      className: css.tooltipFilenameItem,
      key: key
    }, React.createElement("h5", {
      className: css.tooltipFilenameTitle
    }, labels[index]), React.createElement(FileName, {
      name: run && run.name ? run.name : '-'
    }));
  }));
};

TooltipFilename.defaultProps = {
  runs: [],
  labels: []
};
TooltipFilename.propTypes = {
  runs: PropTypes.array,
  // eslint-disable-line react/forbid-prop-types
  labels: PropTypes.array // eslint-disable-line react/forbid-prop-types

};

var getRenderRowHeader = function getRenderRowHeader(labels) {
  return function (item) {
    return React.createElement(React.Fragment, null, item.isNotPredictive && React.createElement(Tooltip, {
      className: css.notPredictive,
      title: React.createElement(TooltipNotPredictive, {
        runs: item.runs
      }),
      align: "topLeft"
    }, React.createElement("span", {
      className: cx('ui-icon ui-icon--small', css.notPredictiveIcon)
    }, "warning")), item.isEntry && React.createElement("span", {
      title: "Entrypoint",
      className: css.flagEntry
    }, "e"), item.isInitial && React.createElement("span", {
      title: "Initial",
      className: css.flagInitial
    }, "i"), item.isChunk && React.createElement("span", {
      title: "Chunk",
      className: css.flagChunk
    }, "c"), React.createElement(Tooltip, {
      title: React.createElement(TooltipFilename, {
        runs: item.runs,
        labels: labels
      }),
      align: "topLeft"
    }, React.createElement(FileName, {
      name: item.label
    })));
  };
};

export var BundleAssets = function BundleAssets(props) {
  var _entryTypes, _ref6;

  var className = props.className,
      runs = props.runs,
      items = props.items,
      updateFilters = props.updateFilters,
      totalRowCount = props.totalRowCount,
      filters = props.filters,
      sortItems = props.sortItems,
      sort = props.sort,
      updateSort = props.updateSort;
  var labeledRuns = runs.map(addRunLabel);
  return React.createElement("section", {
    className: cx(css.root, className)
  }, React.createElement("header", {
    className: css.header
  }, React.createElement(SortDropdown, _extends({
    className: css.dropdown,
    items: sortItems
  }, sort, {
    onChange: updateSort
  })), React.createElement(FiltersDropdown, {
    className: css.dropdown,
    filters: (_ref6 = {}, _ref6[FILTER_CHANGED] = {
      label: 'Changed',
      defaultValue: filters.changed,
      disabled: runs.length <= 1
    }, _ref6.entryTypes = (_entryTypes = {
      label: 'Entry type'
    }, _entryTypes[FILTER_ENTRY] = {
      label: 'Entry',
      defaultValue: true
    }, _entryTypes[FILTER_INITIAL] = {
      label: 'Initial',
      defaultValue: true
    }, _entryTypes[FILTER_CHUNK] = {
      label: 'Chunk',
      defaultValue: true
    }, _entryTypes[FILTER_ASSET] = {
      label: 'Asset',
      defaultValue: true
    }, _entryTypes), _ref6.fileTypes = _objectSpread({
      label: 'File type'
    }, getFileTypeFilters()), _ref6),
    label: "Filters (" + items.length + "/" + totalRowCount + ")",
    onChange: updateFilters
  })), React.createElement("main", null, React.createElement(MetricsTable, {
    runs: labeledRuns,
    items: items,
    renderRowHeader: getRenderRowHeader(_map(labeledRuns, 'name'))
  })));
};
BundleAssets.defaultProps = {
  className: '',
  totalRowCount: 0
};
BundleAssets.propTypes = {
  className: PropTypes.string,
  runs: PropTypes.arrayOf(PropTypes.shape({
    internalBuildNumber: PropTypes.number
  })).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    runs: PropTypes.arrayOf(PropTypes.shape({
      displayValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      displayDelta: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }))
  })).isRequired,
  updateFilters: PropTypes.func.isRequired,
  totalRowCount: PropTypes.number,
  filters: PropTypes.shape({
    changed: PropTypes.bool
  }).isRequired,
  sortItems: PropTypes.shape((_PropTypes$shape = {}, _PropTypes$shape[PropTypes.string] = PropTypes.shape({
    label: PropTypes.string,
    defaultDirection: PropTypes.bool
  }), _PropTypes$shape)).isRequired,
  sort: PropTypes.shape({
    sortBy: PropTypes.string,
    direction: PropTypes.string
  }).isRequired,
  updateSort: PropTypes.func.isRequired
};