"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countAssetsBundleTransform = void 0;

const countAssetsBundleTransform = (bundleStats = {}) => {
  const {
    assets
  } = bundleStats;
  const value = Object.keys(assets).length;
  return {
    stats: {
      assetCount: {
        value
      }
    }
  };
};

exports.countAssetsBundleTransform = countAssetsBundleTransform;