function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Container } from '../../ui';
import css from './footer.module.css';
var DOMAIN = 'https://relative-ci.com';
/* eslint-disable jsx-a11y/anchor-has-content */

var StandardLink = function StandardLink(_ref) {
  var to = _ref.to,
      source = _ref.source,
      props = _objectWithoutPropertiesLoose(_ref, ["to", "source"]);

  return React.createElement("a", _extends({
    href: "" + DOMAIN + to + "?utm_source=" + source
  }, props));
};
/* eslint-enable jsx-a11y/anchor-has-content */


StandardLink.propTypes = {
  /** Link to value */
  to: PropTypes.string.isRequired,

  /** UTM source */
  source: PropTypes.string.isRequired
};
export var Footer = function Footer(_ref2) {
  var className = _ref2.className,
      children = _ref2.children,
      Link = _ref2.Link,
      source = _ref2.source;
  return React.createElement(Container, {
    className: cx(css.root, className),
    as: "footer"
  }, children && React.createElement("div", {
    className: css.content
  }, children), React.createElement("div", {
    className: css.info
  }, React.createElement("div", {
    className: css.navigation
  }, React.createElement("div", {
    className: css.navGroup
  }, React.createElement("h3", {
    className: css.navGroupTitle
  }, "Documentation"), React.createElement("nav", {
    className: css.nav
  }, React.createElement(Link, {
    to: "/documentation/setup",
    className: css.navLink,
    source: source
  }, "Setup"), React.createElement(Link, {
    to: "/documentation/resources",
    className: css.navLink,
    source: source
  }, "Resources"))), React.createElement("div", {
    className: css.navGroup
  }, React.createElement("h3", {
    className: css.navGroupTitle
  }, "Tools"), React.createElement("nav", {
    className: css.nav
  }, React.createElement("a", {
    href: "https://www.npmjs.com/package/bundle-stats",
    className: css.navLink,
    target: "_blank",
    rel: "noopener noreferrer",
    title: "In-depth reporting for bundle changes (assets, chunks, modules).",
    source: source
  }, "Bundle Stats"), React.createElement("a", {
    href: "https://compare.relative-ci.com/?utm_source=" + source,
    className: css.navLink,
    target: "_blank",
    rel: "noopener noreferrer",
    title: "Side by side comparison for webpack/lighthouse/browsertime stats",
    source: source
  }, "Compare"), React.createElement("a", {
    href: "https://video-compare.relative-ci.com/?utm_source=" + source,
    className: css.navLink,
    target: "_blank",
    rel: "noopener noreferrer",
    title: "Side by side video playing for webpagetest / browsertime recordings."
  }, "Video Compare"))), React.createElement("div", {
    className: css.navGroup
  }, React.createElement("h3", {
    className: css.navGroupTitle
  }, "Social"), React.createElement("nav", {
    className: css.nav
  }, React.createElement("a", {
    href: "https://github.com/bundle-stats",
    className: css.navLink,
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Github"), React.createElement("a", {
    href: "https://twitter.com/Relative_CI",
    className: css.navLink,
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Twitter")))), React.createElement("div", {
    className: css.branding
  }, React.createElement("p", {
    className: css.copyright
  }, "\xA9 2019 \xA0", React.createElement(Link, {
    className: css.brandingHome,
    to: "/",
    title: "Go to homepage",
    source: source
  }, "RelativeCI")))));
};
Footer.defaultProps = {
  className: '',
  children: null,
  source: 'web',
  Link: StandardLink
};
Footer.propTypes = {
  /** Adopted child class name */
  className: PropTypes.string,

  /** Content */
  children: PropTypes.element,

  /** UTM source */
  source: PropTypes.string,

  /** Link component */
  Link: PropTypes.elementType
};