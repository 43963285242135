import _orderBy from "lodash/orderBy";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import { compose, withProps, withState } from 'recompose';
export var withCustomSort = function withCustomSort(_ref) {
  var sortItems = _ref.sortItems,
      getCustomSort = _ref.getCustomSort,
      itemsKey = _ref.itemsKey,
      _ref$sortBy = _ref.sortBy,
      sortBy = _ref$sortBy === void 0 ? 'default' : _ref$sortBy,
      _ref$direction = _ref.direction,
      direction = _ref$direction === void 0 ? 'asc' : _ref$direction;
  return compose(withProps({
    sortItems: sortItems
  }), withState('sort', 'updateSort', {
    sortBy: sortBy,
    direction: direction
  }), withProps(function (_ref2) {
    var _ref3;

    var sort = _ref2.sort,
        restProps = _objectWithoutPropertiesLoose(_ref2, ["sort"]);

    return _ref3 = {}, _ref3[itemsKey] = _orderBy(restProps[itemsKey], getCustomSort(sort.sortBy), sort.direction), _ref3;
  }));
};