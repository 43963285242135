"use strict";

require("core-js/modules/es.array.flat");

require("core-js/modules/es.array.unscopables.flat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.assetsWebpackTransform = void 0;

var _get2 = _interopRequireDefault(require("lodash/get"));

var _assets = require("../assets");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const IGNORE_PATTERN = /\.map$/;

const assetsWebpackTransform = webpackStats => {
  const webpackAssets = (0, _get2.default)(webpackStats, 'assets', []);
  const webpackChunks = (0, _get2.default)(webpackStats, 'chunks', []);
  const webpackEntrypoints = (0, _get2.default)(webpackStats, 'entrypoints', {});
  const entryItems = Object.values(webpackEntrypoints).map(({
    assets: items
  }) => items).flat();
  const initialItems = Object.values(webpackChunks).filter(({
    initial
  }) => initial).map(({
    files
  }) => files).flat();
  const chunkItems = Object.values(webpackChunks).filter(({
    entry,
    initial
  }) => !entry && !initial).map(({
    files
  }) => files).flat();
  const assets = webpackAssets.reduce((aggregator, asset) => {
    if (IGNORE_PATTERN.test(asset.name)) {
      return aggregator;
    }

    const source = (0, _assets.getAssetName)(asset.name); // @TODO Get an uniq id (based on url, source)

    const id = source;
    const {
      size,
      name
    } = asset;
    return _objectSpread({}, aggregator, {
      [id]: {
        name,
        value: size,
        isEntry: entryItems.includes(name),
        isInitial: initialItems.includes(name),
        isChunk: chunkItems.includes(name)
      }
    });
  }, {});
  return {
    assets
  };
};

exports.assetsWebpackTransform = assetsWebpackTransform;