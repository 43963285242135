import _get from "lodash/get";
import { compose, withProps, withState } from 'recompose';
import { withCustomSort } from '../../hocs/with-custom-sort';
import { SORT_BY_NAME, SORT_BY_SIZE, SORT_BY_DELTA, SORT_BY } from './bundle-chunk-modules.constants';

var getCustomSort = function getCustomSort(sortBy) {
  return function (item) {
    if (sortBy === SORT_BY_NAME) {
      return item.key;
    }

    if (sortBy === SORT_BY_SIZE) {
      return _get(item, 'runs[0].value', 0);
    }

    if (sortBy === SORT_BY_DELTA) {
      return _get(item, 'runs[0].deltaPercentage', 0);
    }

    return [!item.changed, item.key];
  };
};

var getFilterByChanged = function getFilterByChanged(filters) {
  return function (row) {
    if (filters.changed) {
      return row.changed;
    }

    return true;
  };
};

export default compose(withState('filters', 'updateFilters', function (_ref) {
  var runs = _ref.runs;
  return {
    changed: runs && runs.length > 1
  };
}), withProps(function (_ref2) {
  var modules = _ref2.modules,
      filters = _ref2.filters;
  return {
    totalRowsCount: modules.length,
    modules: modules.filter(getFilterByChanged(filters))
  };
}), withCustomSort({
  sortItems: SORT_BY,
  getCustomSort: getCustomSort,
  itemsKey: 'modules'
}));