import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { formatDelta, formatPercentage, getDelta, getMetricType } from '@bundle-stats/utils';
import { Metric } from '../metric';
import { Delta } from '../delta';
import css from './summary-item.module.css';
export var SummaryItem = function SummaryItem(_ref) {
  var className = _ref.className,
      size = _ref.size,
      id = _ref.id,
      data = _ref.data,
      loading = _ref.loading;

  var _ref2 = data || {
    baseline: 0,
    current: 0
  },
      baseline = _ref2.baseline,
      current = _ref2.current;

  var metric = getMetricType(id);
  var diff = getDelta({
    value: baseline
  }, {
    value: current
  });
  var rootClassName = cx(css.root, className, css[size]);
  return React.createElement("div", {
    className: rootClassName
  }, React.createElement("h3", {
    className: css.title
  }, metric.label), React.createElement("div", {
    className: css.currentContainer
  }, !loading ? React.createElement(Metric, {
    className: css.currentMetric,
    value: current,
    formatter: metric.formatter,
    enhanced: true
  }) : React.createElement("span", {
    className: cx(css.currentMetric, css.loading)
  }), !loading && diff && diff.deltaPercentage ? React.createElement(Delta, {
    className: css.delta,
    value: diff.deltaPercentage,
    displayValue: formatDelta(diff.delta, metric.formatter) + " (" + formatDelta(diff.deltaPercentage, formatPercentage) + ")",
    biggerIsBetter: metric.biggerIsBetter
  }) : null), !loading ? React.createElement(Metric, {
    className: css.baselineMetric,
    value: baseline,
    formatter: metric.formatter
  }) : React.createElement("span", {
    className: cx(css.baselineMetric, css.loading)
  }));
};
SummaryItem.defaultProps = {
  className: '',
  data: null,
  size: 'medium'
};
SummaryItem.propTypes = {
  /** Adopted child class name */
  className: PropTypes.string,

  /** Size modifier */
  size: PropTypes.oneOf(['medium', 'large']),

  /** Metric id */
  id: PropTypes.string.isRequired,

  /** Loading flag */
  loading: PropTypes.bool.isRequired,

  /** Summary data */
  data: PropTypes.object // eslint-disable-line react/forbid-prop-types

};