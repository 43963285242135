"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sizeAssetsBundleTransform = void 0;

var _sum2 = _interopRequireDefault(require("lodash/sum"));

var _map2 = _interopRequireDefault(require("lodash/map"));

var _get2 = _interopRequireDefault(require("lodash/get"));

var _fileTypes = require("../assets/file-types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const METRIC_NAME_ALL = 'ALL';
const METRIC_NAME_PREFIX = 'totalSizeByType';

const getMetricName = fileType => `${METRIC_NAME_PREFIX}${fileType}`;

const generateInitialSizeByType = () => _fileTypes.FILE_TYPES.reduce((accumulator, fileType) => _objectSpread({}, accumulator, {
  [getMetricName(fileType)]: {
    value: 0
  }
}), {});

const calculateTotalByType = assets => assets.reduce((accumulator, current) => {
  const fileType = (0, _fileTypes.getFileType)(current.name);
  const statName = getMetricName(fileType);
  const value = accumulator[statName].value + current.value;
  return _objectSpread({}, accumulator, {
    [statName]: {
      value
    }
  });
}, generateInitialSizeByType());

const getFilterInitialAssetsByType = fileType => ({
  name,
  isInitial
}) => (0, _fileTypes.getFileType)(name) === fileType && isInitial; // eslint-disable-line implicit-arrow-linebreak


const calculateInitialTotals = assets => {
  const cssAssets = assets.filter(getFilterInitialAssetsByType(_fileTypes.FILE_TYPE_CSS));
  const jsAssets = assets.filter(getFilterInitialAssetsByType(_fileTypes.FILE_TYPE_JS));
  return {
    totalInitialSizeCSS: {
      value: (0, _sum2.default)((0, _map2.default)(cssAssets, 'value'))
    },
    totalInitialSizeJS: {
      value: (0, _sum2.default)((0, _map2.default)(jsAssets, 'value'))
    }
  };
};

const sizeAssetsBundleTransform = bundleStats => {
  const bundleAssets = Object.values((0, _get2.default)(bundleStats, 'assets', {}));

  const sizes = _objectSpread({
    [getMetricName(METRIC_NAME_ALL)]: {
      value: (0, _sum2.default)((0, _map2.default)(bundleAssets, 'value'))
    }
  }, calculateTotalByType(bundleAssets), {}, calculateInitialTotals(bundleAssets));

  return {
    sizes
  };
};

exports.sizeAssetsBundleTransform = sizeAssetsBundleTransform;