"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMetricChanged = void 0;

var _uniq2 = _interopRequireDefault(require("lodash/uniq"));

var _map2 = _interopRequireDefault(require("lodash/map"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const getMetricChanged = runs => {
  const uniqValues = (0, _uniq2.default)((0, _map2.default)(runs, 'value'));

  if (uniqValues.length > 1) {
    return true;
  }

  const uniqNames = (0, _uniq2.default)((0, _map2.default)(runs, 'name'));

  if (uniqNames.length > 1) {
    return true;
  }

  return false;
};

exports.getMetricChanged = getMetricChanged;