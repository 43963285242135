var _SORT_BY;

export var FILTER_ASSET = 'asset';
export var FILTER_CHANGED = 'changed';
export var FILTER_ENTRY = 'entrypoint';
export var FILTER_INITIAL = 'initial';
export var FILTER_CHUNK = 'chunk';
export var SORT_BY_NAME = 'name';
export var SORT_BY_SIZE = 'size';
export var SORT_BY_DELTA = 'delta';
export var SORT_BY = (_SORT_BY = {}, _SORT_BY[SORT_BY_NAME] = {
  label: 'Name',
  defaultDirection: 'asc'
}, _SORT_BY[SORT_BY_DELTA] = {
  label: 'Delta',
  defaultDirection: 'desc'
}, _SORT_BY[SORT_BY_SIZE] = {
  label: 'Size',
  defaultDirection: 'desc'
}, _SORT_BY);