"use strict";

require("core-js/modules/es.array.flat");

require("core-js/modules/es.array.unscopables.flat");

require("core-js/modules/es.string.replace");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.packagesModulesBundleTransform = void 0;

var _uniqBy2 = _interopRequireDefault(require("lodash/uniqBy"));

var _get2 = _interopRequireDefault(require("lodash/get"));

var _modules = require("../modules");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const PACKAGE_NAMES = /(node_modules|~)\/((!?@(([\w|\-|_|.]*)\/){2})|(([\w|\-|_|.]*)\/))/g;

const getPackageName = moduleName => {
  const found = moduleName.match(PACKAGE_NAMES);

  if (!found) {
    return '';
  }

  const names = found.map(modulePath => modulePath.replace(/.*(node_modules|~)\/(.*)\/$/, '$2'));
  return names.join(':');
};

const packagesModulesBundleTransform = bundleStats => {
  const modulesByChunks = (0, _get2.default)(bundleStats, 'modules', {});
  const modules = (0, _uniqBy2.default)(Object.values(modulesByChunks).map(chunk => {
    const chunkModules = (0, _get2.default)(chunk, 'modules', {});
    return Object.values(chunkModules);
  }).flat(), ({
    name
  }) => name);
  const packages = modules.reduce((agg, {
    name: moduleName,
    value
  }) => {
    const packageName = getPackageName((0, _modules.getModuleName)(moduleName));

    if (!packageName) {
      return agg;
    }

    const packageValue = (0, _get2.default)(agg, [packageName, 'value'], 0);
    return _objectSpread({}, agg, {
      [packageName]: {
        value: packageValue + value
      }
    });
  }, {});
  return {
    packages
  };
};

exports.packagesModulesBundleTransform = packagesModulesBundleTransform;