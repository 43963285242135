import _map from "lodash/map";
import _get from "lodash/get";
import { compose, withProps } from 'recompose';
import { getBrowsertimeMetrics, addMetricsData, mergeRunsById } from '@bundle-stats/utils';
export var enhance = compose(withProps(function (_ref) {
  var jobs = _ref.jobs;
  var runs = jobs.map(function (job) {
    return {
      meta: job,
      browsertime: getBrowsertimeMetrics(_get(job, 'rawData.browsertime'))
    };
  });
  var items = addMetricsData(mergeRunsById(_map(runs, 'browsertime')));
  return {
    runs: runs,
    items: items
  };
}));