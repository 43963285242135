"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createReport = exports.getModulesReport = exports.createRuns = exports.addMetricsData = void 0;

var _uniq2 = _interopRequireDefault(require("lodash/uniq"));

var _map2 = _interopRequireDefault(require("lodash/map"));

var _get2 = _interopRequireDefault(require("lodash/get"));

var _flatMap2 = _interopRequireDefault(require("lodash/flatMap"));

var _metrics = require("../config/metrics");

var _metrics2 = require("../metrics");

var _getStatsByMetrics = require("../stats/get-stats-by-metrics");

var _transforms = require("../transforms");

var _delta = require("./delta");

var _format = require("./format");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const SIZE_METRICS = ['webpack.assets.totalSizeByTypeJS', 'webpack.assets.totalSizeByTypeCSS', 'webpack.assets.totalSizeByTypeIMG', 'webpack.assets.totalSizeByTypeMEDIA', 'webpack.assets.totalSizeByTypeFONT', 'webpack.assets.totalSizeByTypeHTML', 'webpack.assets.totalSizeByTypeOTHER', 'webpack.assets.totalSizeByTypeALL', 'webpack.assets.totalInitialSizeCSS', 'webpack.assets.totalInitialSizeJS'];

const addMetricsData = (entries, metricType) => entries.map(entry => {
  const {
    runs
  } = entry;
  const {
    biggerIsBetter,
    label,
    formatter
  } = (0, _metrics2.getMetricType)(entry.key, metricType);
  return _objectSpread({}, entry, {
    // Metric props
    biggerIsBetter,
    label,
    // Row props
    changed: (0, _metrics2.getMetricChanged)(runs),
    // Runs
    runs: runs.map((run, index) => {
      if (!run || typeof run.value === 'undefined') {
        return null;
      }

      const {
        value
      } = run,
            restRun = _objectWithoutProperties(run, ["value"]);

      const diff = index < runs.length - 1 ? (0, _delta.getDelta)(runs[index + 1], run) : null;
      return _objectSpread({}, restRun, {
        // run data
        value,
        displayValue: formatter(run.value)
      }, diff !== null ? _objectSpread({}, diff, {
        displayDelta: (0, _delta.formatDelta)(diff.delta, formatter),
        displayDeltaPercentage: (0, _delta.formatDelta)(diff.deltaPercentage, _format.formatPercentage)
      }) : {});
    })
  });
});

exports.addMetricsData = addMetricsData;

const createRuns = jobs => jobs.map(({
  internalBuildNumber,
  stats,
  rawData
}) => {
  const webpackStats = (0, _get2.default)(rawData, 'webpack.stats');
  return _objectSpread({
    meta: {
      internalBuildNumber
    },
    sizes: (0, _getStatsByMetrics.getStatsByMetrics)(stats, SIZE_METRICS)
  }, (0, _transforms.assetsWebpackTransform)(webpackStats), {}, (0, _transforms.modulesWebpackTransform)(webpackStats));
});

exports.createRuns = createRuns;

const getModulesReport = runs => (0, _map2.default)((0, _uniq2.default)((0, _flatMap2.default)(runs, ({
  modules
}) => Object.keys(modules))), chunkId => ({
  chunkId,
  chunkNames: (0, _uniq2.default)((0, _flatMap2.default)(runs, run => (0, _get2.default)(run, ['modules', chunkId, 'chunkNames']))),
  modules: addMetricsData((0, _metrics2.mergeRunsById)((0, _map2.default)(runs, run => (0, _get2.default)(run, ['modules', chunkId, 'modules']))), _metrics.METRIC_TYPE_FILE_SIZE)
}));

exports.getModulesReport = getModulesReport;

const createReport = jobs => {
  const runs = createRuns(jobs);
  return {
    runs: (0, _map2.default)(runs, 'meta'),
    sizes: addMetricsData((0, _metrics2.mergeRunsById)((0, _map2.default)(runs, 'sizes'))),
    assets: addMetricsData((0, _metrics2.mergeRunsById)((0, _map2.default)(runs, 'assets')), _metrics.METRIC_TYPE_FILE_SIZE),
    modules: getModulesReport(runs)
  };
};

exports.createReport = createReport;