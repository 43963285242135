import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import css from './chart.module.css';
export var Chart = function Chart(_ref) {
  var className = _ref.className,
      title = _ref.title,
      children = _ref.children;
  return React.createElement("div", {
    className: cx(css.root, className)
  }, title && React.createElement("h3", {
    className: css.title
  }, title), React.createElement("div", {
    className: css.chart
  }, children));
};
Chart.defaultProps = {
  className: '',
  title: '',
  children: null
};
Chart.propTypes = {
  /** Adopted child class name */
  className: PropTypes.string,

  /** Title */
  title: PropTypes.string,

  /** Children content */
  children: PropTypes.element
};