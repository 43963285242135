import _sum from "lodash/sum";
import _max from "lodash/max";
import _map from "lodash/map";
import _get from "lodash/get";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { addMetricsData, getStatsByMetrics, mergeRunsById } from '@bundle-stats/utils';
import { HorizontalBarChart } from '../../ui';
import { SummaryItem } from '../summary-item';
import { getColors } from '../chart/chart.utils';
import css from './bundle-assets-totals-chart-bars.module.css';
var METRICS = ['webpack.assets.totalSizeByTypeJS', 'webpack.assets.totalSizeByTypeCSS', 'webpack.assets.totalSizeByTypeIMG', 'webpack.assets.totalSizeByTypeMEDIA', 'webpack.assets.totalSizeByTypeFONT', 'webpack.assets.totalSizeByTypeHTML', 'webpack.assets.totalSizeByTypeOTHER'];
export var BundleAssetsTotalsChartBars = function BundleAssetsTotalsChartBars(_ref) {
  var className = _ref.className,
      jobs = _ref.jobs;
  var rootClassName = cx(css.root, className);
  var items = addMetricsData(mergeRunsById(_map(jobs, function (job) {
    return getStatsByMetrics(_get(job, 'stats', {}), METRICS);
  })));
  var dataGraphs = [];
  items.forEach(function (_ref2) {
    var runs = _ref2.runs;
    runs.forEach(function (_ref3, runIndex) {
      var value = _ref3.value;
      dataGraphs[runIndex] = [].concat(dataGraphs[runIndex] || [], [value]);
    });
  });

  var maxValues = _max(_map(dataGraphs, function (values) {
    return _sum(values);
  }));

  var maxValue = _max(maxValues);

  var labels = items.map(function (_ref4) {
    var label = _ref4.label;
    return label;
  });
  var colors = getColors(_max(_map(dataGraphs, function (values) {
    return values.length;
  })));

  var getTooltip = function getTooltip(itemIndex, runIndex) {
    return function () {
      return React.createElement(SummaryItem, {
        className: css.itemTooltip,
        id: _get(items, [itemIndex, 'key']),
        data: {
          current: _get(items, [itemIndex, 'runs', runIndex, 'value'], 0),
          baseline: _get(items, [itemIndex, 'runs', runIndex + 1, 'value'], 0)
        },
        size: "large"
      });
    };
  };

  return React.createElement("div", {
    className: rootClassName
  }, React.createElement("h3", {
    className: css.title
  }, "Total size by type"), React.createElement("div", {
    className: css.items
  }, dataGraphs.map(function (data, runIndex) {
    var internalBuildNumber = jobs[runIndex].internalBuildNumber;
    var values = data.map(function (value, valueIndex) {
      return {
        value: value,
        color: colors[valueIndex],
        label: labels[valueIndex],
        getItemTooltip: getTooltip(valueIndex, runIndex)
      };
    });
    return React.createElement("div", {
      key: internalBuildNumber,
      className: css.item
    }, React.createElement("h3", {
      className: css.itemTitle
    }, "Job #" + internalBuildNumber), React.createElement(HorizontalBarChart, {
      className: css.itemChart,
      data: {
        labels: labels,
        values: values
      },
      maxValue: maxValue
    }));
  })));
};
BundleAssetsTotalsChartBars.defaultProps = {
  className: ''
};
BundleAssetsTotalsChartBars.propTypes = {
  className: PropTypes.string,
  jobs: PropTypes.arrayOf(PropTypes.shape({
    internalBuildNumber: PropTypes.number
  })).isRequired
};