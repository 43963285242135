"use strict";

require("core-js/modules/es.array.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMetricDeleted = exports.getMetricAdded = void 0;

var _map2 = _interopRequireDefault(require("lodash/map"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const getMetricAdded = runs => {
  const [current, baseline] = (0, _map2.default)(runs, 'value');
  return Boolean(current !== null && !baseline);
};

exports.getMetricAdded = getMetricAdded;

const getMetricDeleted = runs => {
  const [current, baseline] = (0, _map2.default)(runs, 'value');
  return Boolean(baseline !== null && !current);
};

exports.getMetricDeleted = getMetricDeleted;