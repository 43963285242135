"use strict";

require("core-js/modules/es.array.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mergeRunsById = void 0;

var _omit2 = _interopRequireDefault(require("lodash/omit"));

var _mergeWith2 = _interopRequireDefault(require("lodash/mergeWith"));

var _map2 = _interopRequireDefault(require("lodash/map"));

var _fill2 = _interopRequireDefault(require("lodash/fill"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const mergeWithRuns = (index, count) => (objValue, srcValue) => {
  // if there are no runs, just create an array and fill it with null
  const res = objValue && objValue.runs ? [...objValue.runs] : (0, _fill2.default)(Array(count), null);
  res[index] = (0, _omit2.default)(srcValue, 'type');
  return {
    type: srcValue.type,
    runs: res
  };
};

const mergeRunsById = runs => {
  const runsCount = runs.length;
  const metricsById = runs.reduce((aggregator, run, index) => (0, _mergeWith2.default)(aggregator, run, mergeWithRuns(index, runsCount)), {});
  return (0, _map2.default)(metricsById, (value, key) => _objectSpread({
    key
  }, value));
};

exports.mergeRunsById = mergeRunsById;