function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import css from './box.module.css';
export var Box = function Box(_ref) {
  var className = _ref.className,
      Component = _ref.as,
      props = _objectWithoutPropertiesLoose(_ref, ["className", "as"]);

  var rootClassName = cx(css.root, className);
  return React.createElement(Component, _extends({
    className: rootClassName
  }, props));
};
Box.defaultProps = {
  className: '',
  as: 'div'
};
Box.propTypes = {
  /** Adopted child class name */
  className: PropTypes.string,

  /** Rendered component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
};