"use strict";

require("core-js/modules/es.array.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createJobs = exports.createJob = void 0;

var _set2 = _interopRequireDefault(require("lodash/set"));

var _reverse2 = _interopRequireDefault(require("lodash/reverse"));

var _last2 = _interopRequireDefault(require("lodash/last"));

var _get2 = _interopRequireDefault(require("lodash/get"));

var _create = require("../stats/create");

var _createSummary = require("../stats/create-summary");

var _extractData = require("../utils/extract-data");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const RAW_DATA_IDS = ['webpack.stats', 'lighthouse', 'browsertime'];
/*
 * Create job from stats
 */

const createJob = (source, baseline) => {
  const data = RAW_DATA_IDS.reduce((agg, rawDataPath) => {
    const rawData = (0, _get2.default)(source, rawDataPath);

    if (rawData) {
      (0, _set2.default)(agg, `rawData.${rawDataPath}`, (0, _extractData.extractDataFromWebpackStats)(rawData));
    }

    return _objectSpread({}, agg);
  }, {});
  const stats = (0, _create.createStats)(baseline && baseline.rawData, data.rawData);
  const summary = (0, _createSummary.createStatsSummary)(baseline && baseline.stats, stats);
  return _objectSpread({}, data, {
    stats,
    summary
  });
};
/*
 * Create jobs from sources
 */


exports.createJob = createJob;

const createJobs = sources => {
  const jobs = (0, _reverse2.default)([...sources]).reduce((agg, source, idx) => [_objectSpread({}, createJob(source, (0, _last2.default)(agg)), {
    internalBuildNumber: sources.length - idx
  }), ...agg], []);
  return jobs;
};

exports.createJobs = createJobs;