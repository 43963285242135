import css from './chart.module.css';
export var RESPONSIVE_CONTAINER_PROPS = {
  minWidth: '320px',
  minHeight: '120px',
  width: '100%',
  height: '100%'
};
export var BAR_CHART_PROPS = {
  margin: 0
};
export var BAR_PROPS = {
  isAnimationActive: false
};
export var PIE_PROPS = {
  isAnimationActive: false
};
export var TOOLTIP_PROPS = {
  isAnimationActive: false,
  cursor: {
    fill: '#FAFAFA'
  },
  contentClassName: css.tooltip
};
export var XAXIS_PROPS = {
  stroke: '#A4A4A4'
};
export var YAXIS_PROPS = {
  stroke: '#A4A4A4'
};