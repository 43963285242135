"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countPackagesBundleTransform = void 0;

var _get2 = _interopRequireDefault(require("lodash/get"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const countPackagesBundleTransform = bundleStats => {
  const packages = (0, _get2.default)(bundleStats, 'packages', {});
  const value = Object.values(packages).length;
  return {
    stats: {
      packageCount: {
        value
      }
    }
  };
};

exports.countPackagesBundleTransform = countPackagesBundleTransform;