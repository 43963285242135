"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.modulesWebpackTransform = void 0;

var _get2 = _interopRequireDefault(require("lodash/get"));

var _modules = require("../modules");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const getChunkNames = (chunks = [], chunkId) => {
  const chunk = chunks.find(({
    id
  }) => id === chunkId);

  if (!chunk) {
    return [];
  }

  return chunk.names;
};
/*
 * Transform webpack modules array to an object with metrics
 */


const modulesWebpackTransform = (webpackStats = {}) => {
  const chunks = (0, _get2.default)(webpackStats, 'chunks', []);
  const modules = (0, _get2.default)(webpackStats, 'modules', []);

  if (!modules) {
    return {
      modules: {}
    };
  }

  const modulesByChunk = modules.reduce((aggregator, moduleEntry) => {
    const {
      name,
      size
    } = moduleEntry;
    const moduleChunks = (0, _get2.default)(moduleEntry, 'chunks', []);
    const normalizedName = (0, _modules.getModuleName)(name);
    return moduleChunks.reduce((aggWithChunks, chunkId) => _objectSpread({}, aggWithChunks, {
      [chunkId]: {
        chunkNames: getChunkNames(chunks, chunkId),
        modules: _objectSpread({}, (0, _get2.default)(aggWithChunks, [chunkId, 'modules']), {
          [normalizedName]: {
            name,
            value: size
          }
        })
      }
    }), aggregator);
  }, {});
  return {
    modules: modulesByChunk
  };
};

exports.modulesWebpackTransform = modulesWebpackTransform;