import _map from "lodash/map";
import _get from "lodash/get";
import { compose, withProps } from 'recompose';
import { getLighthouseMetrics, addMetricsData, mergeRunsById } from '@bundle-stats/utils';
export var enhance = compose(withProps(function (_ref) {
  var jobs = _ref.jobs;
  var runs = jobs.map(function (job) {
    return {
      meta: job,
      lighthouse: getLighthouseMetrics(_get(job, 'rawData.lighthouse'))
    };
  });
  var items = addMetricsData(mergeRunsById(_map(runs, 'lighthouse')));
  return {
    runs: runs,
    items: items
  };
}));