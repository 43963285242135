import _map from "lodash/map";
import _get from "lodash/get";
import React from 'react';
import PropTypes from 'prop-types';
import { addMetricsData, getStatsByMetrics, mergeRunsById } from '@bundle-stats/utils';
import { MetricsTable } from '../metrics-table';
import { JobName } from '../job-name';
var METRICS = ['webpack.assets.totalSizeByTypeJS', 'webpack.assets.totalSizeByTypeCSS', 'webpack.assets.totalSizeByTypeIMG', 'webpack.assets.totalSizeByTypeMEDIA', 'webpack.assets.totalSizeByTypeFONT', 'webpack.assets.totalSizeByTypeHTML', 'webpack.assets.totalSizeByTypeOTHER', 'webpack.assets.totalSizeByTypeALL'];

var getRun = function getRun(job, index) {
  var internalBuildNumber = _get(job, 'internalBuildNumber');

  if (!internalBuildNumber) {
    return {
      label: ' '
    };
  }

  return {
    label: React.createElement(JobName, {
      title: index === 0 ? 'Current' : 'Baseline',
      internalBuildNumber: internalBuildNumber
    })
  };
};

export var BundleAssetsTotalsTable = function BundleAssetsTotalsTable(_ref) {
  var className = _ref.className,
      jobs = _ref.jobs;
  var runs = jobs.map(getRun);
  var items = addMetricsData(mergeRunsById(_map(jobs, function (job) {
    return getStatsByMetrics(_get(job, 'stats', {}), METRICS);
  })));
  return React.createElement(MetricsTable, {
    className: className,
    runs: runs,
    items: items
  });
};
BundleAssetsTotalsTable.defaultProps = {
  className: '',
  jobs: []
};
BundleAssetsTotalsTable.propTypes = {
  /** Addopted child class name */
  className: PropTypes.string,

  /** Jobs data */
  jobs: PropTypes.array // eslint-disable-line react/forbid-prop-types

};