"use strict";

require("core-js/modules/es.array.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFileType = exports.FILE_TYPES = exports.FILE_TYPE_LABELS = exports.FILE_TYPE_PATTERNS = exports.FILE_TYPE_OTHER = exports.FILE_TYPE_HTML = exports.FILE_TYPE_FONT = exports.FILE_TYPE_MEDIA = exports.FILE_TYPE_IMG = exports.FILE_TYPE_JS = exports.FILE_TYPE_CSS = void 0;
// Content types a la Chrome Dev Toolbar
const FILE_TYPE_CSS = 'CSS';
exports.FILE_TYPE_CSS = FILE_TYPE_CSS;
const FILE_TYPE_JS = 'JS';
exports.FILE_TYPE_JS = FILE_TYPE_JS;
const FILE_TYPE_IMG = 'IMG';
exports.FILE_TYPE_IMG = FILE_TYPE_IMG;
const FILE_TYPE_MEDIA = 'MEDIA';
exports.FILE_TYPE_MEDIA = FILE_TYPE_MEDIA;
const FILE_TYPE_FONT = 'FONT';
exports.FILE_TYPE_FONT = FILE_TYPE_FONT;
const FILE_TYPE_HTML = 'HTML';
exports.FILE_TYPE_HTML = FILE_TYPE_HTML;
const FILE_TYPE_OTHER = 'OTHER';
exports.FILE_TYPE_OTHER = FILE_TYPE_OTHER;
const FILE_TYPE_PATTERNS = {
  [FILE_TYPE_CSS]: /\.css$/,
  [FILE_TYPE_JS]: /\.js$/,
  [FILE_TYPE_IMG]: /\.(png|jpe?g|webp|gif|svg|ico)$/,
  [FILE_TYPE_MEDIA]: /\.(mp4|mp3|mov)$/,
  [FILE_TYPE_FONT]: /\.(woff|woff2|ttf|otf)$/,
  [FILE_TYPE_HTML]: /\.(html?)$/
};
exports.FILE_TYPE_PATTERNS = FILE_TYPE_PATTERNS;
const FILE_TYPE_LABELS = {
  [FILE_TYPE_CSS]: 'CSS',
  [FILE_TYPE_JS]: 'JS',
  [FILE_TYPE_IMG]: 'IMG',
  [FILE_TYPE_MEDIA]: 'Media',
  [FILE_TYPE_FONT]: 'Fonts',
  [FILE_TYPE_HTML]: 'HTML',
  [FILE_TYPE_OTHER]: 'Other'
};
exports.FILE_TYPE_LABELS = FILE_TYPE_LABELS;
const FILE_TYPES = [...Object.keys(FILE_TYPE_PATTERNS), FILE_TYPE_OTHER];
exports.FILE_TYPES = FILE_TYPES;

const getFileType = filename => {
  const fileType = Object.entries(FILE_TYPE_PATTERNS).find(([, pattern]) => pattern.test(filename));
  return fileType ? fileType[0] : FILE_TYPE_OTHER;
};

exports.getFileType = getFileType;