"use strict";

require("core-js/modules/es.array.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBrowsertimeMetrics = void 0;

var _get2 = _interopRequireDefault(require("lodash/get"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const METRICS = {
  'browsertime.firstPaint': 'statistics.timings.firstPaint.median',
  'browsertime.fullyLoaded': 'statistics.timings.fullyLoaded.median',
  'browsertime.backEndTime': 'statistics.timings.pageTimings.backEndTime.median',
  'browsertime.domContentLoadedTime': 'statistics.timings.pageTimings.domContentLoadedTime.median',
  'browsertime.domInteractiveTime': 'statistics.timings.pageTimings.domInteractiveTime.median',
  'browsertime.domainLookupTime': 'statistics.timings.pageTimings.domainLookupTime.median',
  'browsertime.frontEndTime': 'statistics.timings.pageTimings.frontEndTime.median',
  'browsertime.pageDownloadTime': 'statistics.timings.pageTimings.pageDownloadTime.median',
  'browsertime.pageLoadTime': 'statistics.timings.pageTimings.pageLoadTime.median',
  'browsertime.redirectionTime': 'statistics.timings.pageTimings.redirectionTime.median',
  'browsertime.serverConnectionTime': 'statistics.timings.pageTimings.serverConnectionTime.median',
  'browsertime.serverResponseTime': 'statistics.timings.pageTimings.serverResponseTime.median',
  'browsertime.firstContentfulPaint': 'statistics.timings.paintTiming.first-contentful-paint.median',
  'browsertime.rumSpeedIndex': 'statistics.timings.rumSpeedIndex.median',
  'browsertime.firstVisualChange': 'statistics.visualMetrics.FirstVisualChange.median',
  'browsertime.lastVisualChange': 'statistics.visualMetrics.LastVisualChange.median',
  'browsertime.perceptualSpeedIndex': 'statistics.visualMetrics.PerceptualSpeedIndex.median',
  'browsertime.speedIndex': 'statistics.visualMetrics.SpeedIndex.median',
  'browsertime.visualComplete85': 'statistics.visualMetrics.VisualComplete85.median',
  'browsertime.visualComplete95': 'statistics.visualMetrics.VisualComplete95.median',
  'browsertime.visualComplete99': 'statistics.visualMetrics.VisualComplete99.median'
};

const getBrowsertimeMetrics = browsertime => {
  if (!browsertime) {
    return {};
  }

  return Object.entries(METRICS).reduce((agg, [metricKey, metricPath]) => _objectSpread({}, agg, {
    [metricKey]: {
      value: (0, _get2.default)(browsertime, `0.${metricPath}`, 0)
    }
  }), {});
};

exports.getBrowsertimeMetrics = getBrowsertimeMetrics;