import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { modulesWebpackTransform, getModulesReport } from '@bundle-stats/utils';
import { Box } from '../../ui/box';
import { BundleChunkModules } from '../bundle-chunk-modules';
import css from './bundle-modules.module.css';
export var BundleModules = function BundleModules(_ref) {
  var jobs = _ref.jobs;
  var runs = jobs.map(function (job) {
    return _objectSpread({
      meta: job
    }, modulesWebpackTransform(_get(job, 'rawData.webpack.stats')));
  });
  var modulesReport = getModulesReport(runs);
  return React.createElement(React.Fragment, null, !_isEmpty(modulesReport) && modulesReport.map(function (_ref2) {
    var chunkId = _ref2.chunkId,
        chunkNames = _ref2.chunkNames,
        modules = _ref2.modules;
    return React.createElement(BundleChunkModules, {
      key: chunkNames.join('-') + "-" + chunkId,
      className: css.chunk,
      title: chunkNames.join(', ') + " (id: " + chunkId + ")",
      runs: runs,
      modules: modules
    });
  }), _isEmpty(modulesReport) && React.createElement(Box, {
    className: css.empty
  }, React.createElement("h2", {
    className: css.emptyTitle
  }, "No data available!"), React.createElement("p", {
    className: css.emptyText
  }, "Please make sure Webpack stats are configured correctly."), React.createElement("a", {
    href: "https://relative-ci.com/documentation/setup#1-configure-webpack",
    className: css.emptyLink,
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Read how you can setup Webpack stats")));
};
BundleModules.propTypes = {
  /* Jobs data */
  jobs: PropTypes.array.isRequired // eslint-disable-line react/forbid-prop-types

};