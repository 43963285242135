import _chunk from "lodash/chunk";
import COLORS from './chart.colors.json';
export var getColors = function getColors(count) {
  if (count === void 0) {
    count = 2;
  }

  var chunks = _chunk(COLORS, Math.round(COLORS.length / count));

  return chunks.map(function (chunkColors, index) {
    if (index === 0) {
      return chunkColors[0];
    }

    if (index === chunks.length - 1) {
      return chunkColors[chunkColors.length - 1];
    }

    return chunkColors[Math.floor(chunkColors.length / 2)];
  });
};