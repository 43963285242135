function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import css from './panels.module.css';

var cloneElement = function cloneElement(element) {
  var _element$props = element.props,
      className = _element$props.className,
      restProps = _objectWithoutPropertiesLoose(_element$props, ["className"]);

  return React.cloneElement(element, _objectSpread({}, restProps, {
    className: cx(css.panel, className)
  }));
};

export var Panels = function Panels(_ref) {
  var className = _ref.className,
      children = _ref.children,
      restProps = _objectWithoutPropertiesLoose(_ref, ["className", "children"]);

  return React.createElement("div", _extends({
    className: cx(css.root, className)
  }, restProps), React.Children.map(children, cloneElement));
};
Panels.defaultProps = {
  className: '',
  children: null
};
Panels.propTypes = {
  /** Adopted child class anme */
  className: PropTypes.string,

  /** Content */
  children: PropTypes.node
};