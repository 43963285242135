import _map from "lodash/map";
import _get from "lodash/get";

var _PropTypes$shape;

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Box, FileName, FiltersDropdown, SortDropdown, Tooltip } from '../../ui';
import { JobName } from '../job-name';
import { MetricsTable } from '../metrics-table';
import css from './bundle-chunk-modules.module.css';

var getRenderRowHeader = function getRenderRowHeader(labels) {
  return function (row) {
    return React.createElement(Tooltip, {
      title: React.createElement("div", {
        className: css.nameTooltip
      }, row.runs.map(function (run, index) {
        var key = index;
        return React.createElement("div", {
          className: css.nameTooltipItem,
          key: key
        }, React.createElement("h5", {
          className: css.nameTooltipTitle
        }, labels[index]), React.createElement(FileName, {
          className: css.nameTooltipText,
          name: run && run.name ? run.name : '-'
        }));
      })),
      align: "topLeft"
    }, React.createElement(FileName, {
      className: css.name,
      name: row.label
    }));
  };
};

var getRunLabel = function getRunLabel(run, index, runs) {
  var internalBuildNumber = _get(run, 'meta.internalBuildNumber');

  var name = "Job #" + (internalBuildNumber || runs.length - index); // No baseline?

  if (!run || !run.meta) {
    return _objectSpread({}, run, {
      label: name,
      name: name
    });
  }

  return _objectSpread({}, run, {
    label: React.createElement(JobName, {
      title: index === 0 ? 'Current' : 'Baseline',
      internalBuildNumber: run.meta.internalBuildNumber
    }),
    name: name
  });
};

export var BundleChunkModules = function BundleChunkModules(_ref) {
  var className = _ref.className,
      title = _ref.title,
      runs = _ref.runs,
      modules = _ref.modules,
      totalRowsCount = _ref.totalRowsCount,
      updateFilters = _ref.updateFilters,
      filters = _ref.filters,
      sortItems = _ref.sortItems,
      sort = _ref.sort,
      updateSort = _ref.updateSort;
  var labeledRuns = runs.map(getRunLabel);
  var rootClassName = cx(css.root, className);
  return React.createElement(Box, {
    className: rootClassName
  }, React.createElement("header", {
    className: css.header
  }, title && React.createElement("h3", {
    className: css.headerTitle
  }, title), React.createElement(SortDropdown, _extends({
    className: css.headerDropdown,
    items: sortItems,
    onChange: updateSort
  }, sort)), React.createElement(FiltersDropdown, {
    className: css.headerDropdown,
    filters: {
      changed: {
        label: 'Changed',
        defaultValue: filters.changed,
        disabled: runs.length <= 1
      }
    },
    label: "Filters (" + modules.length + "/" + totalRowsCount + ")",
    onChange: updateFilters
  })), React.createElement(MetricsTable, {
    className: css.table,
    items: modules,
    runs: labeledRuns,
    renderRowHeader: getRenderRowHeader(_map(labeledRuns, 'name'))
  }));
};
BundleChunkModules.defaultProps = {
  className: '',
  title: '',
  modules: [],
  runs: [],
  totalRowsCount: 0
};
BundleChunkModules.propTypes = {
  /** Adopted child class name */
  className: PropTypes.string,

  /** Section title */
  title: PropTypes.string,

  /** Rows data */
  modules: PropTypes.array,
  // eslint-disable-line react/forbid-prop-types

  /** Runs data */
  runs: PropTypes.array,
  // eslint-disable-line react/forbid-prop-types

  /** totals row count */
  totalRowsCount: PropTypes.number,

  /** Update filters handler */
  updateFilters: PropTypes.func.isRequired,

  /** Filters data */
  filters: PropTypes.shape({
    changed: PropTypes.bool
  }).isRequired,
  sortItems: PropTypes.shape((_PropTypes$shape = {}, _PropTypes$shape[PropTypes.string] = PropTypes.shape({
    label: PropTypes.string,
    defaultDirection: PropTypes.bool
  }), _PropTypes$shape)).isRequired,
  sort: PropTypes.shape({
    sortBy: PropTypes.string,
    direction: PropTypes.string
  }).isRequired,
  updateSort: PropTypes.func.isRequired
};