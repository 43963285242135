function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

var ArrowIcon = function ArrowIcon(props) {
  return React.createElement("svg", props, React.createElement("path", {
    fill: "none",
    d: "M0 0h24v24H0V0z"
  }), React.createElement("path", {
    d: "M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"
  }));
};

ArrowIcon.defaultProps = {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor"
};

var BranchIcon = function BranchIcon(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M512 192c-70.625 0-128 57.344-128 128 0 47.219 25.875 88.062 64 110.281V448s0 128-128 128c-53.062 0-94.656 11.375-128 28.812V302.281c38.156-22.219 64-63.062 64-110.281 0-70.656-57.344-128-128-128S0 121.344 0 192c0 47.219 25.844 88.062 64 110.281V721.75C25.844 743.938 0 784.75 0 832c0 70.625 57.344 128 128 128s128-57.375 128-128c0-33.5-13.188-63.75-34.25-86.625C240.375 722.5 270.656 704 320 704c254 0 256-256 256-256v-17.719c38.125-22.219 64-63.062 64-110.281 0-70.656-57.375-128-128-128zm-384-64c35.406 0 64 28.594 64 64s-28.594 64-64 64-64-28.594-64-64 28.594-64 64-64zm0 768c-35.406 0-64-28.625-64-64 0-35.312 28.594-64 64-64s64 28.688 64 64c0 35.375-28.594 64-64 64zm384-512c-35.375 0-64-28.594-64-64s28.625-64 64-64 64 28.594 64 64-28.625 64-64 64z"
  }));
};

BranchIcon.defaultProps = {
  viewBox: "0 0 640 1024",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor"
};

var ClockIcon = function ClockIcon(props) {
  return React.createElement("svg", props, React.createElement("path", {
    fillRule: "evenodd",
    d: "M8 8h3v2H7c-.55 0-1-.45-1-1V4h2v4zM7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7z"
  }));
};

ClockIcon.defaultProps = {
  viewBox: "0 0 14 16",
  "'aria-hidden'": "true",
  fill: "currentColor"
};

var CommitIcon = function CommitIcon(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M694.875 448C666.375 337.781 567.125 256 448 256c-119.094 0-218.375 81.781-246.906 192H0v128h201.094C229.625 686.25 328.906 768 448 768c119.125 0 218.375-81.75 246.875-192H896V448H694.875zM448 640c-70.656 0-128-57.375-128-128 0-70.656 57.344-128 128-128 70.625 0 128 57.344 128 128 0 70.625-57.375 128-128 128z"
  }));
};

CommitIcon.defaultProps = {
  viewBox: "0 0 896 1024",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor"
};

var FilterIcon = function FilterIcon(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"
  }), React.createElement("path", {
    d: "M0 0h24v24H0z",
    fill: "none"
  }));
};

FilterIcon.defaultProps = {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor"
};

var PackageIcon = function PackageIcon(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M480 64L0 192v576l480 128 480-128V192L480 64zM63.875 720.934L63.5 288l384.498 102.533.001 432.833L63.875 720.934zM63.5 224l160.254-42.734L640 292.265v.135l-160 42.667L63.5 224zm832.625 496.934L512.001 823.366l.001-432.833L640 356.4v156l128-34.135V322.267L896.5 288l-.375 432.934zM768 258.267v-.125L351.734 147.138 480 112.934 896.5 224 768 258.267z"
  }));
};

PackageIcon.defaultProps = {
  viewBox: "0 0 1024 1024",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor"
};

var PullRequestIcon = function PullRequestIcon(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M128 64C57.344 64 0 121.344 0 192c0 47.219 25.906 88.062 64 110.281V721.75C25.906 743.938 0 784.75 0 832c0 70.625 57.344 128 128 128s128-57.375 128-128c0-47.25-25.844-88.062-64-110.25V302.281c38.156-22.219 64-63.062 64-110.281 0-70.656-57.344-128-128-128zm0 832c-35.312 0-64-28.625-64-64 0-35.312 28.688-64 64-64 35.406 0 64 28.688 64 64 0 35.375-28.594 64-64 64zm0-640c-35.312 0-64-28.594-64-64s28.688-64 64-64c35.406 0 64 28.594 64 64s-28.594 64-64 64zm576 465.75V320c0-192.5-192-192-192-192h-64V0L256 192l192 192V256h64c56.438 0 64 64 64 64v401.75c-38.125 22.188-64 62.938-64 110.25 0 70.625 57.375 128 128 128s128-57.375 128-128c0-47.25-25.875-88.062-64-110.25zM640 896c-35.312 0-64-28.625-64-64 0-35.312 28.688-64 64-64 35.375 0 64 28.688 64 64 0 35.375-28.625 64-64 64z"
  }));
};

PullRequestIcon.defaultProps = {
  viewBox: "0 0 768 1024",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor"
};

var SortIcon = function SortIcon(props) {
  return React.createElement("svg", props, React.createElement("path", {
    d: "M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z"
  }), React.createElement("path", {
    d: "M0 0h24v24H0z",
    fill: "none"
  }));
};

SortIcon.defaultProps = {
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor"
};
import css from './icon.module.css';
var ICONS = {
  arrow: ArrowIcon,
  branch: BranchIcon,
  clock: ClockIcon,
  commit: CommitIcon,
  filter: FilterIcon,
  "package": PackageIcon,
  pr: PullRequestIcon,
  sort: SortIcon
};
export var Icon = function Icon(_ref) {
  var className = _ref.className,
      glyph = _ref.glyph,
      Component = _ref.as,
      restProps = _objectWithoutPropertiesLoose(_ref, ["className", "glyph", "as"]);

  var Svg = ICONS[glyph];
  return React.createElement(Component, _extends({
    className: cx(css.root, className)
  }, restProps), React.createElement(Svg, {
    className: css.icon
  }));
};
Icon.defaultProps = {
  className: '',
  as: 'span'
};
Icon.propTypes = {
  className: PropTypes.string,
  glyph: PropTypes.string.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func])
};