function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '../../ui';
import css from './job-name.module.css';

var JobNameLabel = function JobNameLabel(_ref) {
  var internalBuildNumber = _ref.internalBuildNumber,
      labelProps = _ref.labelProps;
  return React.createElement(React.Fragment, null, React.createElement("span", labelProps, "Job"), React.createElement("span", null, "#" + internalBuildNumber));
};

JobNameLabel.defaultProps = {
  labelProps: {}
};
JobNameLabel.propTypes = {
  internalBuildNumber: PropTypes.number.isRequired,
  labelProps: PropTypes.shape({
    className: PropTypes.string
  })
};
export var JobName = function JobName(_ref2) {
  var Component = _ref2.as,
      title = _ref2.title,
      internalBuildNumber = _ref2.internalBuildNumber,
      render = _ref2.render,
      restProps = _objectWithoutPropertiesLoose(_ref2, ["as", "title", "internalBuildNumber", "render"]);

  return React.createElement(Tooltip, _extends({
    className: css.root,
    as: Component,
    title: title
  }, restProps), render({
    internalBuildNumber: internalBuildNumber,
    labelProps: {
      className: css.label
    }
  }));
};
JobName.defaultProps = {
  as: 'span',
  title: '',
  render: JobNameLabel
};
JobName.propTypes = {
  /** Rendered component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

  /** Tooltip title */
  title: PropTypes.string,

  /** Job internal build number */
  internalBuildNumber: PropTypes.number.isRequired,

  /** Render function */
  render: PropTypes.func
};