"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getLighthouseMetrics = require("./get-lighthouse-metrics");

Object.keys(_getLighthouseMetrics).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _getLighthouseMetrics[key];
    }
  });
});