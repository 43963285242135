"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createStatsSummary = void 0;

var _merge2 = _interopRequireDefault(require("lodash/merge"));

var _get2 = _interopRequireDefault(require("lodash/get"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const createStatsSummary = (baselineStats, currentStats) => {
  const metrics = ['webpack.assets.totalSizeByTypeALL', 'webpack.assets.totalInitialSizeJS', 'webpack.assets.totalInitialSizeCSS', 'webpack.cacheInvalidation', 'webpack.moduleCount', 'webpack.chunkCount', 'webpack.assetCount', 'webpack.packageCount'];
  return metrics.map(metric => {
    const baselineValue = (0, _get2.default)(baselineStats, `${metric}.value`, 0);
    const currentValue = (0, _get2.default)(currentStats, `${metric}.value`, 0);
    return {
      [metric]: {
        baseline: baselineValue,
        current: currentValue
      }
    };
  }).reduce((agg, current) => (0, _merge2.default)({}, agg, current), {});
};

exports.createStatsSummary = createStatsSummary;