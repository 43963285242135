"use strict";

require("core-js/modules/es.string.replace");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getModuleName = void 0;

var _last2 = _interopRequireDefault(require("lodash/last"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// css ./node_modules/css-loader/dist/cjs.js??ref--6-0!./src/assets/styles/default.styl
const NAME_WITH_LOADERS = /!/; // ./src/index.jsx + 27 modules

const NAME_WITH_MODULES = /\s\+\s\d*\smodules$/;

const getModuleName = label => {
  if (NAME_WITH_LOADERS.test(label)) {
    return (0, _last2.default)(label.split(NAME_WITH_LOADERS));
  }

  if (NAME_WITH_MODULES.test(label)) {
    return label.replace(NAME_WITH_MODULES, '');
  }

  return label;
};

exports.getModuleName = getModuleName;