"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.METRIC_TYPES = exports.METRIC_TYPE_PERCENTAGE = exports.METRIC_TYPE_SCORE = exports.METRIC_TYPE_NUMERIC = exports.METRIC_TYPE_DURATION = exports.METRIC_TYPE_FILE_SIZE = void 0;

var _format = require("../utils/format");

const METRIC_TYPE_FILE_SIZE = 'METRIC_TYPE_FILE_SIZE';
exports.METRIC_TYPE_FILE_SIZE = METRIC_TYPE_FILE_SIZE;
const METRIC_TYPE_DURATION = 'METRIC_TYPE_DURATION';
exports.METRIC_TYPE_DURATION = METRIC_TYPE_DURATION;
const METRIC_TYPE_NUMERIC = 'METRIC_TYPE_NUMERIC';
exports.METRIC_TYPE_NUMERIC = METRIC_TYPE_NUMERIC;
const METRIC_TYPE_SCORE = 'METRIC_TYPE_SCORE';
exports.METRIC_TYPE_SCORE = METRIC_TYPE_SCORE;
const METRIC_TYPE_PERCENTAGE = 'METRIC_TYPE_PERCENTAGE';
exports.METRIC_TYPE_PERCENTAGE = METRIC_TYPE_PERCENTAGE;
const METRIC_TYPES = {
  [METRIC_TYPE_NUMERIC]: {
    biggerIsBetter: false,
    formatter: _format.formatNumber
  },
  [METRIC_TYPE_SCORE]: {
    biggerIsBetter: true,
    formatter: _format.formatNumber
  },
  [METRIC_TYPE_DURATION]: {
    biggerIsBetter: false,
    formatter: _format.formatDuration
  },
  [METRIC_TYPE_FILE_SIZE]: {
    biggerIsBetter: false,
    formatter: _format.formatFileSize
  },
  [METRIC_TYPE_PERCENTAGE]: {
    biggerIsBetter: true,
    formatter: _format.formatPercentage
  }
};
exports.METRIC_TYPES = METRIC_TYPES;
const METRICS = {
  webpack: {
    assets: {
      totalSizeByTypeALL: {
        label: 'Total Size',
        type: METRIC_TYPE_FILE_SIZE
      },
      totalSizeByTypeJS: {
        label: 'JS',
        type: METRIC_TYPE_FILE_SIZE
      },
      totalSizeByTypeCSS: {
        label: 'CSS',
        type: METRIC_TYPE_FILE_SIZE
      },
      totalSizeByTypeIMG: {
        label: 'IMG',
        type: METRIC_TYPE_FILE_SIZE
      },
      totalSizeByTypeMEDIA: {
        label: 'Media',
        type: METRIC_TYPE_FILE_SIZE
      },
      totalSizeByTypeFONT: {
        label: 'Fonts',
        type: METRIC_TYPE_FILE_SIZE
      },
      totalSizeByTypeHTML: {
        label: 'HTML',
        type: METRIC_TYPE_FILE_SIZE
      },
      totalSizeByTypeOTHER: {
        label: 'Other',
        type: METRIC_TYPE_FILE_SIZE
      },
      totalInitialSizeJS: {
        label: 'Initial JS',
        type: METRIC_TYPE_FILE_SIZE
      },
      totalInitialSizeCSS: {
        label: 'Initial CSS',
        type: METRIC_TYPE_FILE_SIZE
      }
    },
    cacheInvalidation: {
      label: 'Cache Invalidation',
      type: METRIC_TYPE_PERCENTAGE,
      biggerIsBetter: false
    },
    moduleCount: {
      label: 'Module Count',
      type: METRIC_TYPE_NUMERIC,
      biggerIsBetter: false
    },
    chunkCount: {
      label: 'Chunk Count',
      type: METRIC_TYPE_NUMERIC,
      biggerIsBetter: false
    },
    assetCount: {
      label: 'Asset Count',
      type: METRIC_TYPE_NUMERIC,
      biggerIsBetter: false
    },
    packageCount: {
      label: 'Package Count',
      type: METRIC_TYPE_NUMERIC,
      biggerIsBetter: false
    }
  },
  lighthouse: {
    score: {
      label: 'Score',
      type: METRIC_TYPE_SCORE
    },
    performanceScore: {
      label: 'Performance',
      type: METRIC_TYPE_SCORE
    },
    accessibilityScore: {
      label: 'Accessibility',
      type: METRIC_TYPE_SCORE
    },
    bestPracticesScore: {
      label: 'Best Practices',
      type: METRIC_TYPE_SCORE
    },
    seoScore: {
      label: 'SEO',
      type: METRIC_TYPE_SCORE
    },
    pwaScore: {
      label: 'PWA',
      type: METRIC_TYPE_SCORE
    },
    speedIndex: {
      label: 'Perceptual Speed Index',
      type: METRIC_TYPE_DURATION
    },
    firstMeaningfulPaint: {
      label: 'First Meaningful Paint',
      type: METRIC_TYPE_DURATION
    },
    timeToFirstByte: {
      label: 'Time To First Byte',
      type: METRIC_TYPE_DURATION
    },
    firstInteractive: {
      label: 'First Interactive',
      type: METRIC_TYPE_DURATION
    },
    totalByteWeight: {
      label: 'Total Weight',
      type: METRIC_TYPE_FILE_SIZE
    },
    domSize: {
      label: 'DOM Size',
      type: METRIC_TYPE_NUMERIC
    }
  },
  browsertime: {
    firstPaint: {
      label: 'First Paint',
      type: METRIC_TYPE_DURATION
    },
    fullyLoaded: {
      label: 'Fully Loaded',
      type: METRIC_TYPE_DURATION
    },
    backEndTime: {
      label: 'BackEnd Time',
      type: METRIC_TYPE_DURATION
    },
    domContentLoadedTime: {
      label: 'DOM Content Loaded Time',
      type: METRIC_TYPE_DURATION
    },
    domInteractiveTime: {
      label: 'DOM Interactive Time',
      type: METRIC_TYPE_DURATION
    },
    domainLookupTime: {
      label: 'Domain Lookup Time',
      type: METRIC_TYPE_DURATION
    },
    frontEndTime: {
      label: 'FrontEnd Time',
      type: METRIC_TYPE_DURATION
    },
    pageDownloadTime: {
      label: 'Page Download Time',
      type: METRIC_TYPE_DURATION
    },
    pageLoadTime: {
      label: 'Page Load Time',
      type: METRIC_TYPE_DURATION
    },
    redirectionTime: {
      label: 'Redirection Time',
      type: METRIC_TYPE_DURATION
    },
    serverConnectionTime: {
      label: 'Server Connection Time',
      type: METRIC_TYPE_DURATION
    },
    serverResponseTime: {
      label: 'Server Response Time',
      type: METRIC_TYPE_DURATION
    },
    firstContentfulPaint: {
      label: 'First Contentful Paint',
      type: METRIC_TYPE_DURATION
    },
    rumSpeedIndex: {
      label: 'RUM Speed Index',
      type: METRIC_TYPE_DURATION
    },
    firstVisualChange: {
      label: 'First Visual Change',
      type: METRIC_TYPE_DURATION
    },
    lastVisualChange: {
      label: 'Last Visual Change',
      type: METRIC_TYPE_DURATION
    },
    perceptualSpeedIndex: {
      label: 'Perceptual SpeedIndex',
      type: METRIC_TYPE_DURATION
    },
    speedIndex: {
      label: 'Speed Index',
      type: METRIC_TYPE_DURATION
    },
    visualComplete85: {
      label: 'Visual Complete 85',
      type: METRIC_TYPE_DURATION
    },
    visualComplete95: {
      label: 'Visual Complete 95',
      type: METRIC_TYPE_DURATION
    },
    visualComplete99: {
      label: 'Visual Complete 99',
      type: METRIC_TYPE_DURATION
    }
  }
};
var _default = METRICS;
exports.default = _default;