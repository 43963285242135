import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import css from './loader.module.css';
export var Loader = function Loader(_ref) {
  var className = _ref.className;
  return React.createElement("span", {
    className: cx(css.root, className)
  }, React.createElement("span", {
    className: css.loader
  }));
};
Loader.defaultProps = {
  className: ''
};
Loader.propTypes = {
  /** Adopted child class name */
  className: PropTypes.string
};