var _PropTypes$shape;

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Box } from '../../ui/box';
import { SummaryItem } from '../summary-item';
import css from './summary.module.css';
export var Summary = function Summary(_ref) {
  var className = _ref.className,
      data = _ref.data,
      keys = _ref.keys,
      loading = _ref.loading;

  var getRenderSummaryItem = function getRenderSummaryItem(itemProps) {
    return function (key) {
      return React.createElement(SummaryItem, _extends({
        key: key,
        className: css.item,
        id: key,
        data: data && data[key],
        loading: loading
      }, itemProps));
    };
  };

  return React.createElement(Box, {
    className: cx(css.root, className)
  }, React.createElement("div", {
    className: css.items
  }, keys.slice(0, 3).map(getRenderSummaryItem({
    size: 'large'
  }))), React.createElement("div", {
    className: css.items
  }, keys.slice(3).map(getRenderSummaryItem())));
};
Summary.defaultProps = {
  className: '',
  data: null,
  keys: ['webpack.assets.totalSizeByTypeALL', 'webpack.assets.totalInitialSizeJS', 'webpack.assets.totalInitialSizeCSS', 'webpack.cacheInvalidation', 'webpack.moduleCount', 'webpack.chunkCount', 'webpack.assetCount', 'webpack.packageCount'],
  loading: false
};
Summary.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape((_PropTypes$shape = {}, _PropTypes$shape[PropTypes.string] = PropTypes.shape({
    baseline: PropTypes.number,
    current: PropTypes.number
  }), _PropTypes$shape)),
  keys: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool
};