function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import css from './container.module.css';
export var Container = function Container(_ref) {
  var className = _ref.className,
      Component = _ref.as,
      restProps = _objectWithoutPropertiesLoose(_ref, ["className", "as"]);

  return React.createElement(Component, {
    className: cx(css.root, className)
  }, React.createElement("div", _extends({
    className: css.inner
  }, restProps)));
};
Container.defaultProps = {
  className: '',
  as: 'div'
};
Container.propTypes = {
  /** Adopted child class name */
  className: PropTypes.string,

  /** Custom component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};