"use strict";

require("core-js/modules/es.array.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extractDataFromWebpackStats = void 0;

var _toPairs2 = _interopRequireDefault(require("lodash/fp/toPairs"));

var _pick2 = _interopRequireDefault(require("lodash/fp/pick"));

var _filter2 = _interopRequireDefault(require("lodash/fp/filter"));

var _map2 = _interopRequireDefault(require("lodash/fp/map"));

var _get2 = _interopRequireDefault(require("lodash/fp/get"));

var _fromPairs2 = _interopRequireDefault(require("lodash/fp/fromPairs"));

var _flow2 = _interopRequireDefault(require("lodash/fp/flow"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const PATH_IGNORE_PATTERN = '.map$';
/**
 * Extract assets, entrypoints, chunks, from the webpack stats
 */

const extractDataFromWebpackStats = (source, options = {}) => {
  const pathIgnorePattern = new RegExp(options.pathIgnorePattern || PATH_IGNORE_PATTERN);
  const assets = (0, _flow2.default)([(0, _get2.default)('assets'), (0, _map2.default)((0, _pick2.default)(['name', 'size'])), (0, _filter2.default)(({
    name
  }) => !pathIgnorePattern.test(name))])(source);
  const entrypoints = (0, _flow2.default)([(0, _get2.default)('entrypoints'), _toPairs2.default, (0, _map2.default)(([key, value]) => [key, (0, _pick2.default)('assets')(value)]), _fromPairs2.default])(source);
  const chunks = (0, _flow2.default)([(0, _get2.default)('chunks'), (0, _map2.default)((0, _pick2.default)(['id', 'entry', 'initial', 'files', 'names']))])(source);
  const modules = (0, _flow2.default)([(0, _get2.default)('modules'), (0, _map2.default)((0, _pick2.default)(['name', 'size', 'chunks']))])(source);
  return {
    assets,
    entrypoints,
    chunks,
    modules
  };
};

exports.extractDataFromWebpackStats = extractDataFromWebpackStats;