import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import css from './delta.module.css';
var LEVEL_CHANGE = 5;
export var Delta = function Delta(_ref) {
  var _cx;

  var className = _ref.className,
      value = _ref.value,
      displayValue = _ref.displayValue,
      biggerIsBetter = _ref.biggerIsBetter;
  var positiveChange = biggerIsBetter && value > 0 || !biggerIsBetter && value < 0;
  var absValue = Math.abs(value);
  var rootClassName = cx(css.root, className, (_cx = {}, _cx[css.negative] = !positiveChange && absValue >= LEVEL_CHANGE, _cx[css.slightlyNegative] = !positiveChange && absValue > 0 && absValue < LEVEL_CHANGE, _cx[css.slightlyPositive] = positiveChange && absValue > 0 && absValue < LEVEL_CHANGE, _cx[css.positive] = positiveChange && absValue >= LEVEL_CHANGE, _cx));
  return React.createElement("span", {
    className: rootClassName
  }, displayValue);
};
Delta.defaultProps = {
  className: '',
  biggerIsBetter: true
};
Delta.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
  displayValue: PropTypes.string.isRequired,
  biggerIsBetter: PropTypes.bool
};