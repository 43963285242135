import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './metric.module.css'; // Separate value and unit

var EXTRACT_VALUE_UNIT_PATTERN = /([\d|.|,| ]*)(\w*|%)$/;
export var Metric = function Metric(_ref) {
  var className = _ref.className,
      value = _ref.value,
      formatter = _ref.formatter,
      children = _ref.children,
      enhanced = _ref.enhanced;
  var formattedValue = formatter(value); // eslint-disable-next-line no-unused-vars

  var _ref2 = enhanced && formattedValue ? formattedValue.match(EXTRACT_VALUE_UNIT_PATTERN) : [null, formattedValue, null],
      matched = _ref2[0],
      displayValue = _ref2[1],
      displayUnit = _ref2[2];

  return React.createElement("span", {
    className: cx(className, styles.root)
  }, React.createElement("div", {
    className: styles.display
  }, React.createElement("span", {
    className: styles.displayValue
  }, displayValue), displayUnit && React.createElement("span", {
    className: styles.displayUnit
  }, displayUnit)), React.createElement("span", {
    className: styles.delta
  }, children));
};
Metric.defaultProps = {
  className: '',
  enhanced: false,
  value: 0,
  children: [],
  formatter: function formatter(val) {
    return val;
  }
};
Metric.propTypes = {
  className: PropTypes.string,
  enhanced: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  formatter: PropTypes.func,
  children: PropTypes.node
};