import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from '../icon';
import css from './dropdown.module.css';
var ALIGN_LEFT = 'left';
var ALIGN_RIGHT = 'right';
export var Dropdown = function Dropdown(props) {
  var className = props.className,
      label = props.label,
      glyph = props.glyph,
      open = props.open,
      dropdownToggle = props.dropdownToggle,
      align = props.align,
      children = props.children;
  var rootClassName = cx(css.root, open && css.open, css[align], className);
  return React.createElement("div", {
    className: rootClassName
  }, React.createElement("button", {
    className: css.label,
    type: "button",
    onClick: dropdownToggle
  }, React.createElement(Icon, {
    className: css.labelIcon,
    glyph: glyph
  }), label), React.createElement("div", {
    className: css.dropdown
  }, typeof children === 'function' ? children({
    dropdownToggle: dropdownToggle
  }) : children));
};
Dropdown.defaultProps = {
  className: '',
  label: '',
  align: ALIGN_LEFT
};
Dropdown.propTypes = {
  /** Adopted child class name */
  className: PropTypes.string,

  /** Button label */
  label: PropTypes.string,

  /** Icon glyph */
  glyph: PropTypes.string.isRequired,

  /** Dropdown open state */
  open: PropTypes.bool.isRequired,

  /** Align modifier */
  align: PropTypes.oneOf([ALIGN_LEFT, ALIGN_RIGHT]),

  /** Content */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]).isRequired,

  /** Dropdown toggle handler */
  dropdownToggle: PropTypes.func.isRequired
};