import _filter from "lodash/filter";
import _get from "lodash/get";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { compose, withProps, withState } from 'recompose';
import { FILE_TYPES, METRIC_TYPE_FILE_SIZE, addMetricsData, getFileType, assetsWebpackTransform, mergeRunsById } from '@bundle-stats/utils';
import { withCustomSort } from '../../hocs/with-custom-sort';
import { FILTER_ASSET, FILTER_CHANGED, FILTER_CHUNK, FILTER_ENTRY, FILTER_INITIAL, SORT_BY_NAME, SORT_BY_DELTA, SORT_BY_SIZE, SORT_BY } from './bundle-assets.constants';

var addRowFlags = function addRowFlags(_ref) {
  var items = _ref.items;
  var updatedItems = items.map(function (item) {
    var runs = item.runs;
    var isEntry = runs.map(function (run) {
      return run && run.isEntry;
    }).includes(true);
    var isInitial = runs.map(function (run) {
      return run && run.isInitial;
    }).includes(true);
    var isChunk = runs.map(function (run) {
      return run && run.isChunk;
    }).includes(true);
    var isAsset = !(isEntry || isInitial || isChunk);
    return _objectSpread({}, item, {
      isEntry: isEntry,
      isInitial: isInitial,
      isChunk: isChunk,
      isAsset: isAsset
    });
  });
  return {
    items: updatedItems
  };
};

var getIsNotPredictive = function getIsNotPredictive(key, runs) {
  return runs.reduce(function (agg, current, index) {
    if (agg) {
      return agg;
    }

    if (index + 1 === runs.length) {
      return agg;
    }

    if (current && runs[index + 1] && current.delta !== 0 && key !== current.name && current.name === runs[index + 1].name) {
      return true;
    }

    return agg;
  }, false);
};

var addRowIsNotPredictive = function addRowIsNotPredictive(_ref2) {
  var items = _ref2.items;
  return {
    items: items.map(function (item) {
      return _objectSpread({}, item, {
        isNotPredictive: getIsNotPredictive(item.key, item.runs)
      });
    })
  };
};

var getRowFilter = function getRowFilter(filters) {
  return function (item) {
    if (filters[FILTER_CHANGED] && !item.changed) {
      return false;
    }

    if (!(filters["entryTypes." + FILTER_ENTRY] && item.isEntry || filters["entryTypes." + FILTER_INITIAL] && item.isInitial || filters["entryTypes." + FILTER_CHUNK] && item.isChunk || filters["entryTypes." + FILTER_ASSET] && item.isAsset)) {
      return false;
    }

    if (!filters["fileTypes." + getFileType(item.key)]) {
      return false;
    }

    return true;
  };
};

var getCustomSort = function getCustomSort(sortId) {
  return function (item) {
    if (sortId === SORT_BY_NAME) {
      return item.key;
    }

    if (sortId === SORT_BY_DELTA) {
      return _get(item, 'runs[0].deltaPercentage', 0);
    }

    if (sortId === SORT_BY_SIZE) {
      return _get(item, 'runs[0].value', 0);
    }

    return [!item.isNotPredictive, !item.changed, !item.isInitial, !item.isEntry, !item.isChunk, item.key];
  };
};

var getFileTypeFilters = function getFileTypeFilters(value) {
  if (value === void 0) {
    value = true;
  }

  return FILE_TYPES.reduce(function (agg, fileTypeFilter) {
    var _objectSpread2;

    return _objectSpread({}, agg, (_objectSpread2 = {}, _objectSpread2["fileTypes." + fileTypeFilter] = value, _objectSpread2));
  }, {});
};

var getEntryTypeFilters = function getEntryTypeFilters(value) {
  if (value === void 0) {
    value = true;
  }

  return [FILTER_ENTRY, FILTER_INITIAL, FILTER_CHUNK, FILTER_ASSET].reduce(function (agg, entryTypeFilter) {
    var _objectSpread3;

    return _objectSpread({}, agg, (_objectSpread3 = {}, _objectSpread3["entryTypes." + entryTypeFilter] = value, _objectSpread3));
  }, {});
};

export var enhance = compose(withProps(function (_ref3) {
  var jobs = _ref3.jobs;
  var runs = jobs.map(function (job) {
    return {
      meta: job
    };
  });
  var jobsAssets = jobs.map(function (job) {
    var _assetsWebpackTransfo = assetsWebpackTransform(_get(job, 'rawData.webpack.stats')),
        assets = _assetsWebpackTransfo.assets;

    return assets;
  });
  var items = addMetricsData(mergeRunsById(jobsAssets), METRIC_TYPE_FILE_SIZE);
  return {
    runs: runs,
    items: items
  };
}), // @TODO run both transformations in one pass
withProps(addRowFlags), withProps(addRowIsNotPredictive), withState('filters', 'updateFilters', function (_ref4) {
  var _objectSpread4;

  var jobs = _ref4.jobs;
  return _objectSpread((_objectSpread4 = {}, _objectSpread4[FILTER_CHANGED] = jobs.length > 1, _objectSpread4), getEntryTypeFilters(true), {}, getFileTypeFilters(true));
}), withProps(function (_ref5) {
  var items = _ref5.items,
      filters = _ref5.filters;
  return {
    totalRowCount: items.length,
    items: _filter(items, getRowFilter(filters))
  };
}), withCustomSort({
  sortItems: SORT_BY,
  getCustomSort: getCustomSort,
  itemsKey: 'items'
}));