import _get from "lodash/get";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Dropdown } from '../dropdown';
import css from './filters-dropdown.module.css';

var Checkbox = function Checkbox(_ref) {
  var label = _ref.label,
      name = _ref.name,
      getOnOnlyClick = _ref.getOnOnlyClick,
      inputProps = _objectWithoutPropertiesLoose(_ref, ["label", "name", "getOnOnlyClick"]);

  var id = "filter-" + name;
  return React.createElement("div", {
    className: css.filter
  }, React.createElement("label", {
    className: css.filterLabel
  }, React.createElement("input", _extends({
    className: css.filterInput,
    type: "checkbox",
    id: id,
    name: name
  }, inputProps)), React.createElement("span", {
    className: css.filterText
  }, label)), getOnOnlyClick && React.createElement("button", {
    className: css.filterOnlyButton,
    type: "button",
    onClick: getOnOnlyClick(name)
  }, "only"));
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  getOnOnlyClick: PropTypes.func
};
Checkbox.defaultProps = {
  getOnOnlyClick: null
};

var renderTree = function renderTree(key, data, values, onCheckboxChange, getOnOnlyClick) {
  if (typeof data.defaultValue !== 'undefined') {
    return React.createElement(Checkbox, {
      key: key,
      name: key,
      label: data.label,
      onChange: onCheckboxChange,
      checked: values[key],
      disabled: data.disabled,
      getOnOnlyClick: getOnOnlyClick
    });
  }

  if (typeof data === 'object') {
    var groupLabel = data.label,
        groupData = _objectWithoutPropertiesLoose(data, ["label"]);

    var isRootGroup = !key;
    var groupItems = Object.entries(groupData);
    var groupCheckboxes = key && groupItems.filter(function (_ref2) {
      var itemKey = _ref2[0],
          item = _ref2[1];
      return typeof item.defaultValue !== 'undefined';
    }) || [];
    var isGroupChecked = groupCheckboxes.map(function (_ref3) {
      var itemKey = _ref3[0];
      return _get(values, key + "." + itemKey);
    }).reduce(function (agg, val) {
      return agg && val;
    }, true);

    var onGroupClearAll = function onGroupClearAll() {
      groupCheckboxes.forEach(function (_ref4) {
        var itemKey = _ref4[0],
            item = _ref4[1];
        onCheckboxChange({
          target: {
            name: key + "." + itemKey,
            checked: false
          }
        });
      });
    };

    var onGroupCheckAll = function onGroupCheckAll() {
      groupCheckboxes.forEach(function (_ref5) {
        var itemKey = _ref5[0],
            item = _ref5[1];
        onCheckboxChange({
          target: {
            name: key + "." + itemKey,
            checked: true
          }
        });
      });
    };

    var getOnGroupFilterOnlyClick = !isRootGroup ? function (filterKey) {
      return function () {
        onGroupClearAll();
        onCheckboxChange({
          target: {
            name: filterKey,
            checked: true
          }
        });
      };
    } : null;
    return React.createElement("div", {
      className: css.group,
      key: key
    }, React.createElement("div", {
      className: css.groupHeader
    }, groupLabel && React.createElement(React.Fragment, null, React.createElement("h3", {
      className: css.groupTitle
    }, groupLabel), isGroupChecked ? React.createElement("button", {
      className: css.groupHeaderButton,
      type: "button",
      onClick: onGroupClearAll
    }, "clear all") : React.createElement("button", {
      className: css.groupHeaderButton,
      type: "button",
      onClick: onGroupCheckAll
    }, "check all"))), groupItems.map(function (_ref6) {
      var itemKey = _ref6[0],
          itemData = _ref6[1];
      return renderTree([].concat(key ? [key] : [], [itemKey]).join('.'), itemData, values, onCheckboxChange, getOnGroupFilterOnlyClick);
    }));
  }

  return null;
};

export var FiltersDropdown = function FiltersDropdown(props) {
  var className = props.className,
      label = props.label,
      values = props.values,
      filters = props.filters,
      toggleFilter = props.toggleFilter;

  var onCheckboxChange = function onCheckboxChange(_ref7) {
    var target = _ref7.target;
    return toggleFilter(target.name, target.checked);
  };

  var rootClassName = cx(css.root, className);
  return React.createElement(Dropdown, {
    className: rootClassName,
    label: label,
    glyph: "filter",
    align: "right"
  }, React.createElement("form", {
    className: css.dropdown
  }, renderTree('', filters, values, onCheckboxChange)));
};
FiltersDropdown.defaultProps = {
  className: '',
  label: 'Filters'
};
FiltersDropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.object.isRequired,
  // eslint-disable-line react/forbid-prop-types
  filters: PropTypes.object.isRequired,
  // eslint-disable-line react/forbid-prop-types
  toggleFilter: PropTypes.func.isRequired
};