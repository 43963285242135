import _omit from "lodash/omit";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import css from './table.module.css';

var getColumnAttributes = function getColumnAttributes(headers, hasRowHeader, cellId) {
  if (!headers) {
    return {};
  }

  var headerId = hasRowHeader ? cellId + 1 : cellId;
  var header = headers[headerId];
  return typeof header === 'object' ? _omit(header, ['children']) : {};
};

var Th = function Th(props) {
  var className = props.className,
      restProps = _objectWithoutPropertiesLoose(props, ["className"]);

  return React.createElement("th", _extends({
    className: cx(css.cell, className)
  }, restProps));
};

Th.defaultProps = {
  className: ''
};
Th.propTypes = {
  /** Adopted child class name */
  className: PropTypes.string
};

var Td = function Td(props) {
  var className = props.className,
      restProps = _objectWithoutPropertiesLoose(props, ["className"]);

  return React.createElement("td", _extends({
    className: cx(css.cell, className)
  }, restProps));
};

Td.defaultProps = {
  className: ''
};
Td.propTypes = {
  /** Adopted child class name */
  className: PropTypes.string
};

var renderHeader = function renderHeader(header, index) {
  var headerProps = _objectSpread({
    key: header && header.key || index
  }, typeof header === 'object' && !React.isValidElement(header) ? header : {
    children: header
  });

  return React.createElement(Th, headerProps);
};

export var Table = function Table(_ref) {
  var className = _ref.className,
      emptyMessage = _ref.emptyMessage,
      outline = _ref.outline,
      headers = _ref.headers,
      rows = _ref.rows;
  return React.createElement("table", {
    className: cx(className, css.root, outline && css.outline)
  }, headers && headers.length > 0 && React.createElement("thead", null, React.createElement("tr", null, headers.map(renderHeader))), React.createElement("tbody", null, rows.length > 0 && rows.map(function (_ref2, index) {
    var rowCustomClassName = _ref2.className,
        _ref2$cells = _ref2.cells,
        cells = _ref2$cells === void 0 ? [] : _ref2$cells,
        _ref2$header = _ref2.header,
        header = _ref2$header === void 0 ? '' : _ref2$header,
        key = _ref2.key,
        rowProps = _objectWithoutPropertiesLoose(_ref2, ["className", "cells", "header", "key"]);

    var rowClassNames = cx(css.row, rowCustomClassName);
    return React.createElement("tr", _extends({
      className: rowClassNames,
      key: key || index
    }, rowProps), header && renderHeader(header), cells.map(function (cell, cellIndex) {
      var cellProps = _objectSpread({
        key: cell && cell.key || cellIndex
      }, getColumnAttributes(headers, !!header, cellIndex), {}, typeof cell === 'object' && !React.isValidElement(cell) ? cell : {
        children: cell
      });

      return React.createElement(Td, cellProps);
    }));
  }), rows.length === 0 && React.createElement("tr", null, React.createElement(Td, {
    className: css.emptyData,
    colSpan: headers.length || 1
  }, emptyMessage))));
};
Table.defaultProps = {
  className: '',
  emptyMessage: 'No entries found.',
  outline: false,
  headers: [],
  rows: []
};
Table.propTypes = {
  className: PropTypes.string,
  emptyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  outline: PropTypes.bool,
  headers: PropTypes.array,
  // eslint-disable-line react/forbid-prop-types
  rows: PropTypes.array // eslint-disable-line react/forbid-prop-types

};