function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import css from './tooltip.module.css';
export var Tooltip = function Tooltip(props) {
  var className = props.className,
      title = props.title,
      children = props.children,
      Component = props.as,
      align = props.align,
      ref = props.containerRef,
      restProps = _objectWithoutPropertiesLoose(props, ["className", "title", "children", "as", "align", "containerRef"]);

  var rootClassName = cx(css.root, className, align && css[align]);
  return React.createElement(Component, _extends({
    className: rootClassName
  }, ref ? {
    ref: ref
  } : {}, restProps), children, React.createElement("div", {
    className: css.tooltip
  }, title));
};
Tooltip.defaultProps = {
  className: '',
  as: 'span',
  align: '',
  containerRef: null
};
Tooltip.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  align: PropTypes.oneOf(['', 'topLeft']),
  containerRef: PropTypes.shape({
    current: PropTypes.object
  })
};