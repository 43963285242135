function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import { MetricsTable } from '../metrics-table';
import { JobName } from '../job-name';

var getRunLabel = function getRunLabel(run, index) {
  // Current run
  if (index === 0) {
    return _objectSpread({}, run, {
      label: ' '
    });
  } // No baseline?


  if (!run || !run.meta) {
    return _objectSpread({}, run, {
      label: '-'
    });
  } // @TODO: move into a shared component


  return _objectSpread({}, run, {
    label: React.createElement(JobName, {
      title: "Baseline",
      internalBuildNumber: run.meta.internalBuildNumber
    })
  });
};

export var LighthouseTable = function LighthouseTable(props) {
  var className = props.className,
      runs = props.runs,
      items = props.items;
  var labeledRuns = runs.map(getRunLabel);
  return React.createElement(MetricsTable, {
    className: className,
    runs: labeledRuns,
    items: items
  });
};
LighthouseTable.defaultProps = {
  className: '',
  runs: [],
  items: []
};
LighthouseTable.propTypes = {
  className: PropTypes.string,
  runs: PropTypes.array,
  // eslint-disable-line react/forbid-prop-types
  items: PropTypes.array // eslint-disable-line react/forbid-prop-types

};