import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import css from './file-name.css';
var MIN_BREAK_LENGTH = 32;
export var FileName = function FileName(_ref) {
  var className = _ref.className,
      name = _ref.name;

  if (!name) {
    return null;
  }

  var parts = name.split('/');
  return React.createElement("span", {
    className: cx(css.root, className)
  }, parts.map(function (part, index) {
    var key = part + "-" + index;
    var isShort = part.length < MIN_BREAK_LENGTH;
    return React.createElement("span", {
      key: key,
      className: cx(css.part, isShort && css.noBreak)
    }, part);
  }));
};
FileName.defaultProps = {
  className: '',
  name: ''
};
FileName.propTypes = {
  /** Adopted child class name */
  className: PropTypes.string,

  /** File name source  */
  name: PropTypes.string
};