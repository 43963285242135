"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createStats = exports.generateAssetCount = exports.generateChunkCount = exports.generateModuleCount = exports.generatePackageCount = exports.generateCacheInvalidation = exports.generateWebpackTotals = void 0;

var _set2 = _interopRequireDefault(require("lodash/set"));

var _merge2 = _interopRequireDefault(require("lodash/merge"));

var _get2 = _interopRequireDefault(require("lodash/get"));

var _transforms = require("../transforms");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const generateWebpackTotals = key => (_, current) => {
  // @NOTE Temporary generation of normalized assets
  const {
    sizes
  } = (0, _transforms.sizeAssetsBundleTransform)(current);
  return (0, _set2.default)({}, key, sizes);
};

exports.generateWebpackTotals = generateWebpackTotals;

const generateCacheInvalidation = key => (baseline, current) => {
  const {
    stats
  } = (0, _transforms.cacheInvalidationAssetsBundleTransform)(current, baseline);
  return (0, _set2.default)({}, key, stats.cacheInvalidation);
};

exports.generateCacheInvalidation = generateCacheInvalidation;

const generatePackageCount = key => (_, current) => {
  const {
    stats
  } = (0, _transforms.countPackagesBundleTransform)((0, _transforms.packagesModulesBundleTransform)(current));
  return (0, _set2.default)({}, key, stats.packageCount);
};

exports.generatePackageCount = generatePackageCount;

const generateModuleCount = key => (_, current) => {
  const {
    stats
  } = (0, _transforms.countModulesBundleTransform)(current);
  return (0, _set2.default)({}, key, stats.moduleCount);
};

exports.generateModuleCount = generateModuleCount;

const generateChunkCount = key => (_, current) => {
  const {
    stats
  } = (0, _transforms.chunkCountAssetsBundleTransform)(current);
  return (0, _set2.default)({}, key, stats.chunkCount);
};

exports.generateChunkCount = generateChunkCount;

const generateAssetCount = key => (_, current) => {
  const {
    stats
  } = (0, _transforms.countAssetsBundleTransform)(current);
  return (0, _set2.default)({}, key, stats.assetCount);
};

exports.generateAssetCount = generateAssetCount;

const createStats = (baselineRawData, currentRawData) => {
  const baselineWebpackStats = (0, _get2.default)(baselineRawData, 'webpack.stats');

  const baselineBundle = _objectSpread({}, (0, _transforms.assetsWebpackTransform)(baselineWebpackStats), {}, (0, _transforms.modulesWebpackTransform)(baselineWebpackStats));

  const currentWebpackStats = (0, _get2.default)(currentRawData, 'webpack.stats');

  const currentBundle = _objectSpread({}, (0, _transforms.assetsWebpackTransform)(currentWebpackStats), {}, (0, _transforms.modulesWebpackTransform)(currentWebpackStats));

  return [generateWebpackTotals('webpack.assets'), generateCacheInvalidation('webpack.cacheInvalidation'), generatePackageCount('webpack.packageCount'), generateModuleCount('webpack.moduleCount'), generateChunkCount('webpack.chunkCount'), generateAssetCount('webpack.assetCount')].map(transform => transform(baselineBundle, currentBundle)).reduce(_merge2.default, {});
};

exports.createStats = createStats;