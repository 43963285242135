import _filter from "lodash/filter";
import _get from "lodash/get";
import { compose, withProps, withState } from 'recompose';
import { METRIC_TYPE_FILE_SIZE, addMetricsData, modulesWebpackTransform, packagesModulesBundleTransform, mergeRunsById } from '@bundle-stats/utils';
import { withCustomSort } from '../../hocs/with-custom-sort';
import { FILTER_CHANGED, SORT_BY_NAME, SORT_BY_DELTA, SORT_BY_SIZE, SORT_BY } from './bundle-packages.constants';

var getRowFilter = function getRowFilter(filters) {
  return function (item) {
    if (filters[FILTER_CHANGED] && !item.changed) {
      return false;
    }

    return true;
  };
};

var getCustomSort = function getCustomSort(sortId) {
  return function (item) {
    if (sortId === SORT_BY_NAME) {
      return item.key;
    }

    if (sortId === SORT_BY_DELTA) {
      return _get(item, 'runs[0].deltaPercentage', 0);
    }

    if (sortId === SORT_BY_SIZE) {
      return _get(item, 'runs[0].value', 0);
    }

    return [!item.changed, item.key];
  };
};

export var enhance = compose(withProps(function (_ref) {
  var jobs = _ref.jobs;
  var runs = jobs.map(function (job) {
    return {
      meta: job
    };
  });
  var jobsPackages = jobs.map(function (job) {
    var bundle = modulesWebpackTransform(_get(job, 'rawData.webpack.stats'));
    var res = packagesModulesBundleTransform(bundle);
    return res.packages;
  });
  var items = addMetricsData(mergeRunsById(jobsPackages), METRIC_TYPE_FILE_SIZE);
  return {
    runs: runs,
    items: items
  };
}), withState('filters', 'updateFilters', function (_ref2) {
  var _ref3;

  var jobs = _ref2.jobs;
  return _ref3 = {}, _ref3[FILTER_CHANGED] = jobs.length > 1, _ref3;
}), withProps(function (_ref4) {
  var items = _ref4.items,
      filters = _ref4.filters;
  return {
    totalRowCount: items.length,
    items: _filter(items, getRowFilter(filters))
  };
}), withCustomSort({
  sortItems: SORT_BY,
  getCustomSort: getCustomSort,
  itemsKey: 'items',
  sortBy: SORT_BY_SIZE,
  direction: SORT_BY[SORT_BY_SIZE].defaultDirection
}));