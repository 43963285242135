"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cacheInvalidationAssetsBundleTransform = void 0;

var _merge2 = _interopRequireDefault(require("lodash/merge"));

var _get2 = _interopRequireDefault(require("lodash/get"));

var _assets = require("../assets");

var _metrics = require("../metrics");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const cacheInvalidationAssetsBundleTransform = (bundleStats, baselineBundleStats) => {
  const currentAssets = (0, _get2.default)(bundleStats, 'assets', []);
  const baselineAssets = (0, _get2.default)(baselineBundleStats, 'assets', []);
  const rows = (0, _metrics.mergeRunsById)([currentAssets, baselineAssets]).map(row => (0, _merge2.default)({}, row, {
    changed: (0, _metrics.getMetricChanged)(row.runs),
    added: (0, _metrics.getMetricAdded)(row.runs),
    deleted: (0, _metrics.getMetricDeleted)(row.runs)
  }));
  const value = (0, _assets.calculateCacheInvalidation)(rows);
  return {
    stats: {
      cacheInvalidation: {
        value
      }
    }
  };
};

exports.cacheInvalidationAssetsBundleTransform = cacheInvalidationAssetsBundleTransform;