import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import css from './alert.module.css';
export var Alert = function Alert(props) {
  var className = props.className,
      kind = props.kind,
      children = props.children;
  return React.createElement("div", {
    className: cx(css.root, className, css[kind])
  }, children);
};
Alert.propTypes = {
  className: PropTypes.string,
  kind: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
};
Alert.defaultProps = {
  className: '',
  kind: 'default'
};