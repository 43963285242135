import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Table } from '../../ui/table';
import { Metric } from '../metric';
import { Delta } from '../delta';
import styles from './metrics-table.module.css';

var generateHeaderCell = function generateHeaderCell(item, index, runs) {
  return {
    children: item && item.label || item && item.meta && item.meta.internalBuildNumber && "Run #" + item.meta.internalBuildNumber || item && "Run #" + (runs.length - index) || '-',
    className: cx(styles.value, index ? styles.baseline : styles.current)
  };
};

var getHeaders = function getHeaders(runs) {
  return [// Metric name column - one empty strying to render the column
  {
    children: ' ',
    className: styles.metricName
  }].concat(runs.map(generateHeaderCell));
};

var generateRowCell = function generateRowCell(_ref) {
  var biggerIsBetter = _ref.biggerIsBetter;
  return function (item) {
    if (!item || typeof item.value === 'undefined') {
      return '-';
    }

    var displayValue = item.displayValue,
        deltaPercentage = item.deltaPercentage,
        displayDeltaPercentage = item.displayDeltaPercentage;
    return React.createElement(Metric, {
      value: displayValue
    }, deltaPercentage ? React.createElement(Delta, {
      value: deltaPercentage,
      displayValue: displayDeltaPercentage,
      biggerIsBetter: biggerIsBetter
    }) : null);
  };
};

var getRows = function getRows(runs, items, renderRowHeader) {
  return items.map(function (item) {
    var biggerIsBetter = item.biggerIsBetter,
        changed = item.changed;
    return {
      className: changed ? '' : styles.unchanged,
      cells: [// Metric name
      renderRowHeader(item)].concat(item.runs.map(generateRowCell({
        biggerIsBetter: biggerIsBetter
      })))
    };
  });
};

export var MetricsTable = function MetricsTable(_ref2) {
  var className = _ref2.className,
      renderRowHeader = _ref2.renderRowHeader,
      runs = _ref2.runs,
      items = _ref2.items;
  return React.createElement(Table, {
    className: cx(styles.root, className, runs.length > 1 && styles.multipleRuns),
    headers: getHeaders(runs),
    rows: getRows(runs, items, renderRowHeader)
  });
};
MetricsTable.defaultProps = {
  className: '',
  renderRowHeader: function renderRowHeader(item) {
    return item.label;
  }
};
MetricsTable.propTypes = {
  className: PropTypes.string,
  renderRowHeader: PropTypes.func,
  runs: PropTypes.arrayOf(PropTypes.shape({
    internalBuildNumber: PropTypes.number
  })).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    runs: PropTypes.arrayOf(PropTypes.shape({
      displayValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      displayDelta: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }))
  })).isRequired
};