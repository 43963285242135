import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Container } from '../../ui/container';
import css from './sub-header.module.css';
var SIZE_SMALL = 'small';
var SIZE_DEFAULT = 'default';
var SIZES = [SIZE_SMALL, SIZE_DEFAULT];
export var SubHeader = function SubHeader(props) {
  var className = props.className,
      size = props.size,
      subtitle = props.subtitle,
      title = props.title,
      icon = props.icon,
      children = props.children,
      rightSide = props.rightSide;
  var rootClassName = cx(css.root, css[size], className);
  return React.createElement(Container, {
    className: rootClassName
  }, React.createElement("div", {
    className: css.inner
  }, icon && React.createElement("div", {
    className: css.icon
  }, icon), React.createElement("div", {
    className: css.details
  }, subtitle && React.createElement("p", {
    className: css.subtitle
  }, subtitle), React.createElement("h1", {
    className: css.title
  }, title), children), rightSide && React.createElement("div", {
    className: css.rightSide
  }, rightSide)));
};
SubHeader.defaultProps = {
  className: '',
  size: SIZE_DEFAULT,
  subtitle: '',
  icon: null,
  children: null,
  rightSide: null
};
SubHeader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(SIZES),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.element,
  children: PropTypes.element,
  rightSide: PropTypes.element
};