"use strict";

require("core-js/modules/es.array.iterator");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLighthouseMetrics = void 0;

var _round2 = _interopRequireDefault(require("lodash/round"));

var _mean2 = _interopRequireDefault(require("lodash/mean"));

var _get2 = _interopRequireDefault(require("lodash/get"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const getScore = res => {
  const scores = Object.values(res.categories).map(category => category.score);
  return (0, _round2.default)((0, _mean2.default)(scores) * 100);
};

const getCategoryScore = metricPath => res => (0, _get2.default)(res, metricPath, 0) * 100;

const METRICS = {
  'lighthouse.score': getScore,
  'lighthouse.performanceScore': getCategoryScore('categories.performance.score'),
  'lighthouse.accessibilityScore': getCategoryScore('categories.accessibility.score'),
  'lighthouse.bestPracticesScore': getCategoryScore('categories.best-practices.score'),
  'lighthouse.seoScore': getCategoryScore('categories.seo.score'),
  'lighthouse.pwaScore': getCategoryScore('categories.pwa.score'),
  'lighthouse.speedIndex': 'audits.speed-index.numericValue',
  'lighthouse.firstMeaningfulPaint': 'audits.first-meaningful-paint.numericValue',
  'lighthouse.timeToFirstByte': 'audits.time-to-first-byte.numericValue',
  'lighthouse.firstInteractive': 'audits.first-cpu-idle.numericValue',
  'lighthouse.totalByteWeight': 'audits.total-byte-weight.numericValue',
  'lighthouse.domSize': 'audits.dom-size.numericValue'
};

const getLighthouseMetrics = data => {
  if (!data) {
    return {};
  }

  return Object.entries(METRICS).reduce((agg, [metricKey, metricPath]) => _objectSpread({}, agg, {
    [metricKey]: {
      value: typeof metricPath === 'function' ? metricPath(data) : (0, _get2.default)(data, metricPath, 0)
    }
  }), {});
};

exports.getLighthouseMetrics = getLighthouseMetrics;