"use strict";

require("core-js/modules/es.string.replace");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAssetName = void 0;

var _last2 = _interopRequireDefault(require("lodash/last"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const FILENAME_HASH_PATTERN = /[.|-][a-f0-9]{5,32}$/;

const getAssetName = assetFilepath => {
  const pathParts = assetFilepath.split('/');
  const dirname = pathParts.slice(0, -1).join('/');
  const filename = (0, _last2.default)(pathParts);
  const filenameParts = filename.split('.');
  const {
    basename,
    extension
  } = filenameParts.slice(-2, -1).join('') === 'min' ? {
    basename: filenameParts.slice(0, -2).join('.'),
    extension: filenameParts.slice(-2).join('.')
  } : {
    basename: filenameParts.slice(0, -1).join('.'),
    extension: filenameParts.slice(-1).join('.')
  };
  return `${dirname ? `${dirname}/` : ''}${basename.replace(FILENAME_HASH_PATTERN, '')}.${extension}`;
};

exports.getAssetName = getAssetName;