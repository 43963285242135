var _PropTypes$shape;

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Dropdown } from '../dropdown';
import { Icon } from '../icon';
import css from './sort-dropdown.module.css';
export var SortDropdown = function SortDropdown(props) {
  var className = props.className,
      label = props.label,
      items = props.items,
      sortBy = props.sortBy,
      direction = props.direction,
      onChange = props.onChange;
  var rootClassName = cx(css.root, className);

  var getButtonOnClick = function getButtonOnClick(newSortBy, newDirection) {
    return function () {
      onChange({
        sortBy: newSortBy,
        direction: newDirection
      });
    };
  };

  var customLabel = items[sortBy] ? "Ordered by " + items[sortBy].label : label;
  return React.createElement(Dropdown, {
    className: rootClassName,
    label: customLabel,
    glyph: "sort",
    align: "right"
  }, React.createElement("div", {
    className: css.items
  }, Object.entries(items).map(function (_ref) {
    var key = _ref[0],
        item = _ref[1];
    var sortDirectionProps = direction === 'asc' ? {
      className: cx(css.itemSortType, css.itemSortTypeAsc),
      onClick: getButtonOnClick(key, 'desc'),
      title: 'Order data descending'
    } : {
      className: css.itemSortType,
      onClick: getButtonOnClick(key, 'asc'),
      title: 'Order data ascending'
    };
    return React.createElement("span", {
      key: key,
      className: cx(css.item, sortBy === key && css.active)
    }, React.createElement("button", {
      className: css.itemSortBy,
      type: "button",
      onClick: getButtonOnClick(key, item.defaultDirection),
      title: "Order by " + item.label
    }, item.label), React.createElement("button", _extends({
      type: "button"
    }, sortDirectionProps), React.createElement(Icon, {
      className: css.itemSortTypeIcon,
      glyph: "arrow"
    })));
  })));
};
SortDropdown.defaultProps = {
  className: '',
  label: 'Sort by',
  onChange: function onChange() {},
  sortBy: '',
  direction: 'asc'
};
SortDropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.shape((_PropTypes$shape = {}, _PropTypes$shape[PropTypes.string] = PropTypes.string, _PropTypes$shape)).isRequired,
  onChange: PropTypes.func,
  sortBy: PropTypes.string,
  direction: PropTypes.string
};