"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _calculateCacheInvalidation = require("./calculate-cache-invalidation");

Object.keys(_calculateCacheInvalidation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _calculateCacheInvalidation[key];
    }
  });
});

var _fileTypes = require("./file-types");

Object.keys(_fileTypes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _fileTypes[key];
    }
  });
});

var _getAssetName = require("./get-asset-name");

Object.keys(_getAssetName).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _getAssetName[key];
    }
  });
});