import _get from "lodash/get";

var _PropTypes$shape;

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FiltersDropdown } from '../../ui/filters-dropdown';
import { SortDropdown } from '../../ui/sort-dropdown';
import { MetricsTable } from '../metrics-table';
import { JobName } from '../job-name';
import { FILTER_CHANGED } from './bundle-packages.constants';
import css from './bundle-packages.module.css';
var RUN_TITLE_CURRENT = 'Current';
var RUN_TITLE_BASELINE = 'Baseline';

var addRunLabel = function addRunLabel(run, index, runs) {
  var internalBuildNumber = _get(run, 'meta.internalBuildNumber');

  var name = "Job #" + (internalBuildNumber || runs.length - index);
  var label = internalBuildNumber ? React.createElement(JobName, {
    title: index === 0 ? RUN_TITLE_CURRENT : RUN_TITLE_BASELINE,
    internalBuildNumber: internalBuildNumber
  }) : name;
  return _objectSpread({}, run, {
    name: name,
    label: label
  });
};

export var BundlePackages = function BundlePackages(props) {
  var _ref;

  var className = props.className,
      runs = props.runs,
      items = props.items,
      updateFilters = props.updateFilters,
      totalRowCount = props.totalRowCount,
      filters = props.filters,
      sortItems = props.sortItems,
      sort = props.sort,
      updateSort = props.updateSort;
  var labeledRuns = runs.map(addRunLabel);
  return React.createElement("section", {
    className: cx(css.root, className)
  }, React.createElement("header", {
    className: css.header
  }, React.createElement(SortDropdown, _extends({
    className: css.dropdown,
    items: sortItems
  }, sort, {
    onChange: updateSort
  })), React.createElement(FiltersDropdown, {
    className: css.dropdown,
    filters: (_ref = {}, _ref[FILTER_CHANGED] = {
      label: 'Changed',
      defaultValue: filters.changed,
      disabled: runs.length <= 1
    }, _ref),
    label: "Filters (" + items.length + "/" + totalRowCount + ")",
    onChange: updateFilters
  })), React.createElement("main", null, React.createElement(MetricsTable, {
    runs: labeledRuns,
    items: items
  })));
};
BundlePackages.defaultProps = {
  className: '',
  totalRowCount: 0
};
BundlePackages.propTypes = {
  className: PropTypes.string,
  runs: PropTypes.arrayOf(PropTypes.shape({
    internalBuildNumber: PropTypes.number
  })).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    runs: PropTypes.arrayOf(PropTypes.shape({
      displayValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      displayDelta: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }))
  })).isRequired,
  updateFilters: PropTypes.func.isRequired,
  totalRowCount: PropTypes.number,
  filters: PropTypes.shape({
    changed: PropTypes.bool
  }).isRequired,
  sortItems: PropTypes.shape((_PropTypes$shape = {}, _PropTypes$shape[PropTypes.string] = PropTypes.shape({
    label: PropTypes.string,
    defaultDirection: PropTypes.bool
  }), _PropTypes$shape)).isRequired,
  sort: PropTypes.shape({
    sortBy: PropTypes.string,
    direction: PropTypes.string
  }).isRequired,
  updateSort: PropTypes.func.isRequired
};