"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatPercentage = exports.formatCommit = exports.formatNumber = exports.formatDuration = exports.formatFileSize = void 0;

var _round2 = _interopRequireDefault(require("lodash/round"));

var _convertUnits = _interopRequireDefault(require("convert-units"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const formatFileSize = val => {
  const res = (0, _convertUnits.default)(val).from('B').toBest();
  return `${(0, _round2.default)(res.val, 2)}${res.unit}`;
};

exports.formatFileSize = formatFileSize;

const formatDuration = val => {
  const res = (0, _convertUnits.default)(val).from('ms').toBest();
  return `${(0, _round2.default)(res.val, 4)}${res.unit}`;
};

exports.formatDuration = formatDuration;

const formatNumber = val => `${val}`;

exports.formatNumber = formatNumber;

const formatCommit = commitSha => commitSha && commitSha.substr(0, 7) || '';

exports.formatCommit = formatCommit;

const formatPercentage = val => `${val}%`;

exports.formatPercentage = formatPercentage;