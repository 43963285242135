"use strict";

require("core-js/modules/es.array.flat");

require("core-js/modules/es.array.unscopables.flat");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countModulesBundleTransform = void 0;

var _uniq2 = _interopRequireDefault(require("lodash/uniq"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const countModulesBundleTransform = (bundleStats = {}) => {
  const {
    modules = {}
  } = bundleStats;
  const value = (0, _uniq2.default)(Object.values(modules).map(({
    modules: chunkModules
  }) => Object.values(chunkModules)).flat().map(({
    name
  }) => name)).length;
  return {
    stats: {
      moduleCount: {
        value
      }
    }
  };
};

exports.countModulesBundleTransform = countModulesBundleTransform;