"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.chunkCountAssetsBundleTransform = void 0;

const chunkCountAssetsBundleTransform = (bundleStats = {}) => {
  const {
    assets
  } = bundleStats;
  const value = Object.values(assets).filter(({
    isChunk
  }) => isChunk).length;
  return {
    stats: {
      chunkCount: {
        value
      }
    }
  };
};

exports.chunkCountAssetsBundleTransform = chunkCountAssetsBundleTransform;