import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import css from './header.module.css';
export var Header = function Header(_ref) {
  var className = _ref.className,
      renderLeft = _ref.renderLeft,
      renderRight = _ref.renderRight,
      render = _ref.render;
  return React.createElement("header", {
    className: cx(css.root, className)
  }, renderLeft({
    className: css.left
  }), render({
    className: css.center
  }), renderRight({
    className: css.right
  }));
};
Header.defaultProps = {
  className: '',
  renderLeft: function renderLeft(props) {
    return React.createElement("div", props);
  },
  render: function render(props) {
    return React.createElement("div", props);
  },
  renderRight: function renderRight(props) {
    return React.createElement("div", props);
  }
};
Header.propTypes = {
  /* Adopted child class name */
  className: PropTypes.string,

  /* Render left area */
  renderLeft: PropTypes.func,

  /* Render center area */
  render: PropTypes.func,

  /* Render right area */
  renderRight: PropTypes.func
};