"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateCacheInvalidation = void 0;

var _round2 = _interopRequireDefault(require("lodash/round"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 * Calculate cache invalidation metric on the baseline data
 *
 * The metric is the ratio between the total file size of the files that have changed (exclude
 * deleted, added) and the total file size
 */
const calculateCacheInvalidation = rows => {
  let cached = 0;
  let invalidated = 0;
  rows.forEach(({
    changed,
    added,
    deleted,
    runs
  }) => {
    // Added and deleted files do not count towards the caching index
    if (added || deleted) {
      return;
    }

    if (changed) {
      invalidated += runs[1].value;
    }

    cached += runs[1].value;
  });

  if (cached === 0) {
    return 0;
  }

  return (0, _round2.default)(invalidated / cached * 100, 2);
};

exports.calculateCacheInvalidation = calculateCacheInvalidation;